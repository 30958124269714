<template>
  <div class="landing__block block" data-animation-trigger="true">
    <div class="block__left" data-animation="true" data-animation-type="move-down">
      <h1 class="landing__title">
        {{ $t("CharlaLandingBlock.title_part1") }}
        <span class="landing__title__img__wrapper">
          <span class="landing__title__img">
            <svg width="64" height="48" viewBox="0 0 64 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" y="-7.5" width="63" height="63" rx="31.5" stroke="white" stroke-dasharray="2 2"/>
              <rect x="8.5" y="0.5" width="47" height="47" rx="23.5" stroke="white" stroke-dasharray="2 2"/>
              <rect x="16.5" y="8.5" width="31" height="31" rx="15.5" stroke="white" stroke-dasharray="2 2"/>
              <rect x="24.5" y="16.5" width="15" height="15" rx="7.5" stroke="white" stroke-dasharray="2 2"/>
            </svg>
          </span>
          {{ $t("CharlaLandingBlock.title_part2") }}
        </span>
        <span>{{ $t("CharlaLandingBlock.title_part3") }} <b class="landing__title__bold">Charla</b></span>
      </h1>
      <div class="landing__title_small" v-html='$t("CharlaLandingBlock.slogan")'></div>
      <div class="landing__join">
        <a rel="nofollow noopener"  href="https://app.charla-ai.com/auth/sign-up" target="_blank" @click="click_buttonFree_new_id7(); sendMetrik('click_button-free_new_id7')" class="landing__join__button">{{ $t("CharlaLandingBlock.btn") }}</a>
        <a rel="nofollow noopener"  href="https://app.charla-ai.com/auth/sign-up" target="_blank" @click="click_buttonFree_new_id7(); sendMetrik('click_button-free_new_id7')" class="landing__join__plus">
          <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10 0H8V6H0V8H8V14H10V8H18V6H10V0Z" fill="black" />
          </svg>
        </a>
      </div>
      <div class="landing__slogan">
        <p>{{ $t("CharlaLandingBlock.info1") }}</p>
        <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="Tr.currentLocale !=='ru'">
          <path d="M10 7L0 14V0L10 7Z" fill="black"/>
        </svg>
        <b class="landing__slogan__bold">{{ $t("CharlaLandingBlock.info2") }}</b>
      </div>
    </div>
    <div class="block__right" data-animation="true" data-animation-type="move-down">
      <div class="landing__carousel__wrapper">
        <div class="landing__carousel" :style="[carouselBool ? {'transform': 'translate(' + carouselTranslate + '%, 0)'} : {'transform': 'translate(-66.66%,0)'}]" data-translate-position="-66.66">
          <div class="landing__carousel__item">
            <div class="landing__carousel__item__numbers">100+</div>
            <div class="landing__carousel__item__title" v-html='$t("CharlaLandingBlock.slider.carouselItem1.title")'></div>
            <p class="landing__carousel__item__text" v-html='$t("CharlaLandingBlock.slider.carouselItem1.text")'></p>
          </div>
          <div class="landing__carousel__item">
            <div class="landing__carousel__item__numbers">93%</div>
            <div class="landing__carousel__item__title" v-html='$t("CharlaLandingBlock.slider.carouselItem2.title")'></div>
            <p class="landing__carousel__item__text" v-html='$t("CharlaLandingBlock.slider.carouselItem2.text")'></p>
          </div>
          <div class="landing__carousel__item">
            <div class="landing__carousel__item__numbers">x46</div>
            <div class="landing__carousel__item__title" v-html='$t("CharlaLandingBlock.slider.carouselItem3.title")'></div>
            <p class="landing__carousel__item__text" v-html='$t("CharlaLandingBlock.slider.carouselItem3.text")'></p>
          </div>
<!--          <div class="landing__carousel__item" v-if="Tr.currentLocale !== 'ru'">-->
<!--            <div class="landing__carousel__item__numbers">100%</h2>-->
<!--            <div class="landing__carousel__item__title" v-html='$t("CharlaLandingBlock.slider.carouselItem4.title")'></div>-->
<!--            <p class="landing__carousel__item__text" v-html='$t("CharlaLandingBlock.slider.carouselItem4.text")'></p>-->
<!--          </div>-->
        </div>
        <div class="landing__carousel__button__wrapper">
          <button :class="this.carouselTranslate > -50 ? 'landing__carousel__button__back_active' : 'landing__carousel__button__back_disabled'" class="landing__carousel__button landing__carousel__button__back" @click="scrollCarousel">
            <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M48 6.58V9.42L41.42 16L40 14.58L45.58 9H8.74234e-08L0 7L45.58 7L40 1.42L41.42 0L48 6.58Z" fill="white" />
            </svg>
          </button>
          <button :class="this.carouselTranslate === 0 ? 'landing__carousel__button_disabled' : 'landing__carousel__button_active'" class="landing__carousel__button" @click="scrollCarousel('right')">
            <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M48 6.58V9.42L41.42 16L40 14.58L45.58 9H8.74234e-08L0 7L45.58 7L40 1.42L41.42 0L48 6.58Z" fill="black" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tr from "@/i18n/translation";
import { useGtm } from '@gtm-support/vue-gtm';
import { sendMetrik } from "@/utils/metrica";

export default {
  name: "CharlaLandingBlock",
  data() {
    return {
      carouselTranslate: 0,
      carouselBool: true
    }
  },
  computed: {
    Tr() {
      return Tr
    }
  },
  props: {
    animateClass: ''
  },
  methods: {
    sendMetrik: sendMetrik,
    click_buttonFree_new_id7(){
      const gtm = useGtm()
      gtm.dataLayer().push({
        'event':'click_button-free_new_id7',
        'eventCategory':'click',
        'eventAction':'button-free',
        'eventLabel':'new_id7'
      })
    },
    scrollCarousel(direction) {
      const carouselButtons = [...document.querySelectorAll('.landing__carousel__button')];
      const carousel = document.querySelector('.landing__carousel');

      if (direction === 'right'){
        this.carouselTranslate += 33.33
      } else {
        this.carouselTranslate -= 33.33
      }

      carousel.dataset.translatePosition = `${this.carouselTranslate}`
    }
  },
  mounted() {
    const splideContainer= document.querySelector('.splide')
    const carousel = document.querySelector('.landing__carousel')
    this.carouselTranslate = +carousel.dataset.translatePosition

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          splideContainer.dataset.stopScroll = 'false'
        } else {
          splideContainer.dataset.stopScroll = 'true'
        }
      })
    }, {
      threshold: 1
    });

    const carouselItem = document.querySelector('.landing__carousel__item');
    observer.observe(carouselItem)

    const config = {
      attributes: true,
    };
    const callback = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'attributes'){
          this.carouselTranslate = +carousel.dataset.translatePosition
        }
      })
    }

    const mutationObserver = new MutationObserver(callback)
    mutationObserver.observe(carousel, config)
  }
}
</script>

<style scoped lang="scss">
  @import "@/scss/mixins";

  .block__right{
    position: relative;
    display: flex;
    align-items: center;
  }

  .landing{
    &__block{
      display: grid;
      grid-template: 1fr / 1fr 1fr;
      min-height: calc(100vh - 48px);
      justify-items: flex-start;
      padding: 24px 0;
      opacity: 1;
      transition: 300ms ease;
      scroll-snap-align: start;
      position: relative;
      z-index: 0;
      overflow: hidden;

      &__left{
        width: calc((50vw - 32px));
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        position: relative;
        gap: 32px;
        padding: 0 0 0 176px;
      }

      &__right{
        width: calc((50vw - 12px));
        display: grid;
        align-items: center;
        position: relative;
        overflow: hidden;
      }
    }

    &__title{
      display: flex;
      flex-direction: column;
      font-weight: 400;
      font-size: 66px;
      color: #000;

      &_small{
        display: flex;
        flex-direction: column;
        font-weight: 600;
        font-size: 22px;
        line-height: 127%;
        color: #000;
      }

      &__bold{
        font-weight: 600;
      }

      &__img{
        background-color: #FF6332;
        overflow: hidden;
        width: 88px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 24px;
        transform: translate(0, -4px);

        &__wrapper{
          display: flex;
          align-items: flex-end;
          gap: 16px;
        }
      }
    }

    &__join{
      display: flex;
      position: relative;
      max-width: 420px;
      width: 100%;
      height: 48px;
      gap: 8px;
      transition: 300ms ease gap;

      &__button{
        padding: 13px 24px;
        background-color: black;
        height: 48px;
        color: white;
        font-weight: 600;
        font-size: 14px;
        line-height: 157%;
        border-radius: 24px;
        cursor: pointer;
        transition: 300ms all ease;
        display: flex;
        justify-content: left;
      }

      &__plus{
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: 1px solid black;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 300ms all ease;

        & > svg > path{
          transition: 300ms ease fill;
        }
      }
    }

    &__slogan{
      font-weight: 400;
      font-size: 14px;
      display: flex;
      gap: 16px;

      &__bold{
        font-weight: 600;
      }
    }

    &__carousel{
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      justify-items: flex-start;
      width: fit-content;
      transition: 300ms ease transform;
      position: relative;

      &__wrapper{
        position: relative;
        overflow: hidden;
        width: 100%;
        height: calc(224px + 152px);
      }

      &__button{
        background-color: white;
        padding: 15px 28px;
        border-radius: 24px;
        display: grid;
        place-items: center;
        transition: 300ms ease all;
        position: relative;
        z-index: 0;

        & > svg{
          transition: 300ms ease all;
        }

        &:hover > svg{
          transform: translate(10px, 0);
        }

        &:active > svg{
          transform: translate(10px, 0);
        }

        &_active{
          opacity : 1;
          transform : translate(0);
          pointer-events: all;
        }

        &_disabled{
          opacity : 0;
          transform : translate(-114px, 0);
          pointer-events: none;
        }

        &__back{
          z-index: -1;
          background-color: black;
          transform: rotate(180deg);

          &_active{
            opacity: 1;
            transform: translate(0) rotate(180deg);
            pointer-events : all;
            z-index: 1;
          }

          &_disabled{
            opacity: 0;
            transform: translate(114px) rotate(180deg);
            pointer-events: none;
            z-index: -1;
          }
        }

        &__wrapper{
          display: flex;
          justify-content: flex-end;
          position: absolute;
          width: 448px;
          bottom: 0;
          gap: 10px;
        }
      }

      & > .landing__carousel__item:nth-child(1){
        background-color: var(--purple);
      }
      & > .landing__carousel__item:nth-child(2){
        background-color: var(--green);
      }
      & > .landing__carousel__item:nth-child(3){
        background-color: #FF6332;
      }
      & > .landing__carousel__item:nth-child(4){
        background-color: #b561f6;

        & > .landing__carousel__item__numbers{
          padding: 0 10px 0 22px;
        }
      }

      &__item{
        width: 270px;
        padding: 32px 0;
        display: grid;
        grid-template: 1fr 60px 1fr / 1fr;
        //flex-direction: column;
        //justify-content: space-between;
        //gap: 16px;
        border-radius: 32px;
        height: 314px;

        &__numbers{
          padding: 0 32px;
          font-weight: 700;
          font-size: 56px;
          color: #fff;
          line-height: 100%;
        }

        &__title{
          padding: 0 16px 0 32px;
          font-weight: 600;
          font-size: 22px;
          color: #000;
          line-height: 127%;
          display: flex;
          align-items: center;
        }

        &__text{
          font-weight: 400;
          font-size: 12px;
          line-height: 150%;
          color: #fff;
          padding: 0 32px;
          display: flex;
          align-items: flex-end;
        }
      }
    }
  }

  .landing__join:hover{
    gap: 0;
  }

  .landing__join:hover > .landing__join__button {
    background-color: var(--purple);
    border-radius: 24px 0 0 24px;
  }

  .landing__join:hover > .landing__join__plus{
    border: 1px solid var(--purple);
    background-color: var(--purple);
    border-radius: 0 24px 24px 0;
  }

  .landing__join:hover > .landing__join__plus > svg > path{
    fill: white;
  }



  @media screen and (max-width: 1600px) {
    .landing__title{
      font-size: 48px;
      line-height: 117%;
    }

    .landing__title__img{
      width: 64px;
      height: 40px;
      transform: translate(0, -6px);
    }
  }
  @media screen and (max-width: 1300px){
    .landing__carousel__button__wrapper,
    .carousel__button__wrapper {
      width: 420px;
    }
  }

</style>