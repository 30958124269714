import { createApp } from 'vue'
import App from './App.vue'
import { createGtm } from "@gtm-support/vue-gtm";
// import gsap from 'gsap'
// import Vue from "vue";
import i18n from "@/i18n";
import router from './router'
import components from '@/components/UI/index'
import VueSplide from '@splidejs/vue-splide';
import store from "@/store";

const app = createApp(App);
app.config.devtools = true

components.forEach(component => {
    app.component(component.name, component)
})

app.use(router)
    .use(VueSplide)
    .use(store)
    .use(i18n)
    .use(createGtm({
        id: 'GTM-MT7TLCGR',
        vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    }),
    )
    .mount('#app');

