<template>
  <CharlaBurgerMenu></CharlaBurgerMenu>
  <!--  <CharlaForm></CharlaForm>-->
  <CharlaLangPopup></CharlaLangPopup>
  <CharlaPrivacyPopup></CharlaPrivacyPopup>
  <CharlaTermsPopup></CharlaTermsPopup>
  <CharlaAboutPopup></CharlaAboutPopup>
  <CharlaFaqPopup></CharlaFaqPopup>
  <CharlaFormPopup></CharlaFormPopup>
  <CharlaWorkingPopup></CharlaWorkingPopup>
  <CharlaCookieNotification></CharlaCookieNotification>
  <CharlaCookiePopup></CharlaCookiePopup>
  <div class="dekstop" :class="{ 'ru-locale': Tr.currentLocale === 'ru' }">
    <CharlaAside @moveTo="makeMove(splide)"></CharlaAside>
    <CharlaHeader></CharlaHeader>
    <main class="main__container" @mousedown="customScroll">
      <section class="splide">
        <CharlaBackground></CharlaBackground>
        <div class="splide__track">
          <ul class="splide__list">
            <li class="splide__slide" data-slide-number="0">
              <CharlaLandingBlock></CharlaLandingBlock>
            </li>
            <li class="splide__slide" data-slide-number="1">
              <CharlaHowItWork></CharlaHowItWork>
            </li>
            <li class="splide__slide" data-slide-number="2">
              <CharlaCompare></CharlaCompare>
            </li>
            <li class="splide__slide" data-slide-number="3">
              <CharlaMoney></CharlaMoney>
            </li>
            <li class="splide__slide" data-slide-number="4">
              <CharlaStatistics></CharlaStatistics>
            </li>
            <li class="splide__slide" id="#application" data-slide-number="5">
              <CharlaApplications></CharlaApplications>
            </li>
            <li class="splide__slide" data-slide-number="6">
              <CharlaReview></CharlaReview>
            </li>
            <li class="splide__slide" data-slide-number="7">
              <CharlaAdvantages></CharlaAdvantages>
            </li>
            <li class="splide__slide" data-slide-number="8">
              <CharlaPrice></CharlaPrice>
            </li>
            <li class="splide__slide" data-slide-number="9">
              <CharlaQA></CharlaQA>
            </li>
            <li class="splide__slide">
            </li>
          </ul>
        </div>
      </section>
    </main>
    <CharlaIcons></CharlaIcons>
    <CharlaFooter></CharlaFooter>
  </div>
  <CharlaMobile></CharlaMobile>
</template>

<script>
import CharlaHeader from "@/components/CharlaHeader.vue";
import CharlaAside from "@/components/CharlaAside.vue";
import CharlaBackground from "@/components/CharlaBackground.vue";
import CharlaLandingBlock from "@/components/CharlaLandingBlock.vue"
import CharlaFooter from "@/components/CharlaFooter.vue";
import CharlaHowItWork from "@/components/CharlaHowItWork.vue";
import CharlaStatistics from "@/components/CharlaStatistics.vue";
import CharlaApplications from "@/components/CharlaApplications.vue";
import CharlaCompare from "@/components/CharlaCompare.vue";
import CharlaMoney from "@/components/CharlaMoney.vue";
import CharlaReview from "@/components/CharlaReview.vue";
import CharlaAdvantages from "@/components/CharlaAdvantages.vue";
import CharlaPrice from "@/components/CharlaPrice.vue";
import CharlaQA from "@/components/CharlaQA.vue";
import CharlaCookieNotification from "@/components/CharlaCookieNotification.vue";
import CharlaCookiePopup from "@/components/CharlaCookiePopup.vue";


import '@splidejs/vue-splide/css/core';
import "@/js/splideInit"

import { mapActions, mapGetters } from "vuex";
import CharlaIcons from "@/components/CharlaIcons.vue";
import CharlaMobile from "@/components/mobile/CharlaMobile.vue";
import CharlaBurgerMenu from "@/components/CharlaBurgerMenu.vue";
import CharlaLangPopup from "@/components/CharlaLangPopup.vue";
import CharlaPrivacyPopup from "@/components/CharlaPrivacyPopup.vue";
import CharlaTermsPopup from "@/components/CharlaTermsPopup.vue";
import CharlaAboutPopup from "@/components/CharlaAboutPopup.vue";
import CharlaFormPopup from "@/components/CharlaFormPopup.vue";
import CharlaWorkingPopup from "@/components/CharlaWorkingPopup.vue";
import Tr from "@/i18n/translation";
import CharlaFaqPopup from "@/components/UI/CharlaFaqPopup.vue";

export default {
  components: {
    CharlaFaqPopup,
    CharlaFormPopup,
    CharlaAboutPopup,
    CharlaTermsPopup,
    CharlaPrivacyPopup,
    CharlaLangPopup,
    CharlaBurgerMenu,
    CharlaMobile,
    CharlaIcons,
    CharlaQA,
    CharlaPrice,
    CharlaAdvantages,
    CharlaReview,
    CharlaMoney,
    CharlaCompare,
    CharlaApplications,
    CharlaStatistics,
    CharlaHowItWork,
    CharlaFooter,
    CharlaBackground,
    CharlaAside,
    CharlaHeader,
    CharlaLandingBlock,
    CharlaWorkingPopup,
    CharlaCookieNotification,
    CharlaCookiePopup
  },
  data() {
    return {
      sliderOptions: {
        direction: "ttb",
        height: '100vh',
        type: 'custom',
        focus: 'center',
        wheel: true,
        snap: true,
        waitForTransition: true,
        wheelSleep: 1000,
        perPage: 1,
        speed: 1500
      },
      datasetScrollAnimation: ['rightScroll', 'default', 'leftScroll', 'default', 'default', 'default', 'default', 'default', 'centerScroll', 'default']
    }
  },
  computed: {
    ...mapGetters(['getCurrentPos']),
    Tr() {
      return Tr
    }

  },
  mounted() {
    const splideContainer = document.querySelector('.splide')
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry, i) => {
        if (entry.isIntersecting) {
          [...entry.target.children].forEach(e => {
            if (e.dataset.animationType === "move-up" && splideContainer.dataset.hardMove !== 'true') {
              e.classList.add('move__up')

              setTimeout(() => {
                e.classList.remove('move__up')
                e.style.transform = 'translate(0, 0)'
                e.style.opacity = '1'
              }, 500)
            } else if (e.dataset.animationType === "move-down" && splideContainer.dataset.hardMove !== 'true') {
              e.classList.add('move__down')
              setTimeout(() => {
                e.classList.remove('move__down')
                e.style.transform = 'translate(0, 0)'
                e.style.opacity = '1'
              }, 500)
            } else if (e.dataset.animationType === "move-up" &&
              splideContainer.dataset.hardMove === 'true' &&
              +splideContainer.dataset.destinationIndex === +entry.target.parentElement.ariaLabel.split(' ')[0] - 1) {
              e.classList.add('move__up')
              setTimeout(() => {
                e.classList.remove('move__up')
                e.style.transform = 'translate(0, 0)'
                e.style.opacity = '1'
              }, 500)
            } else if (e.dataset.animationType === "move-down" &&
              splideContainer.dataset.hardMove === 'true' &&
              +splideContainer.dataset.destinationIndex === +entry.target.parentElement.ariaLabel.split(' ')[0] - 1) {
              e.classList.add('move__down')
              setTimeout(() => {
                e.classList.remove('move__down')
                e.style.transform = 'translate(0, 0)'
                e.style.opacity = '1'
              }, 500)
            }
          })


          splideContainer.dataset.typeScrollAnimation = this.datasetScrollAnimation[+entry.target.parentElement.dataset.slideNumber]
          splideContainer.dataset.stopScroll = 'true'
          animationItems.forEach((e, i) => {
            if (i !== +entry.target.parentElement.dataset.slideNumber) {
              const currentElements = [...e.children]


              if (currentElements[0].dataset.animationType === "move-up") {
                currentElements.forEach(el => {
                  el.style.transform = 'translate(100vw, 0)'
                  el.style.opacity = '0'
                })
              } else if (currentElements[0].animationType === "move-down") {
                currentElements.forEach(el => {
                  el.style.transform = 'translate(100vw, 0)'
                  el.style.opacity = '0'
                })
              }
            }
          })
        }
      })
    }, {
      threshold: 0.5
    });

    const animationItems = [...document.querySelectorAll('[data-animation-trigger*="true"]')]

    animationItems.forEach(e => {
      observer.observe(e);
    })
    this.reload();

    let initialQueryString = window.location.search;
    let currentLandingLang = Tr.currentLocale === "ru" ? '&lang=RU' : '&lang=EN'
    const links = [...document.querySelectorAll('a[href*="app.charla-ai.com"]')]

    if (initialQueryString.includes('?')) {
      links.forEach(e => {
        let href = e.getAttribute('href')
        e.setAttribute('href', href + initialQueryString + currentLandingLang)
      })
    } else {
      links.forEach(e => {
        let href = e.getAttribute('href')
        e.setAttribute('href', href + '?' + currentLandingLang)
      })
    }
  },
  methods: {
    ...mapActions(['getNewPosition']),
    reload() {

    },
    changePagination(splide) {
      const { Controller, Move, Scroll } = splide.Components;
      const animationItems = [...document.querySelectorAll('[data-animation*="true"]')]

      this.getNewPosition(Controller.getIndex())
    },

    makeMove() {
      // const splide = document.querySelector('.splide')
      // const {Move} = splide.Components;
    },

    customScroll(e) {
      if (e.button === 1) {
        e.preventDefault()
      }
    },
  },
  watch: {
    '$i18n.locale'(newLocale, oldLocale) {

      const links = [...document.querySelectorAll('a[href*="app.charla-ai.com"]')]
      links.forEach(e => {
        let href = e.getAttribute('href')
        e.setAttribute('href', Tr.currentLocale === "ru" ? href.replace('&lang=EN', '&lang=RU') : href.replace('&lang=RU', '&lang=EN'))
      })
    },
  },

}
</script>

<style lang="scss">
@media screen and (min-width: 660px) {
  @import "@/scss/nullstyle";
  @import "@/scss/mixins";


  html,
  body {
    //height: 100vh;
    //overflow: hidden;
  }

  main {
    display: flex;
    padding: 24px 15px 24px 24px;
    flex-direction: column;
    gap: 48px;
    //overflow: scroll;
    overflow: hidden;
    height: 100vh;
    position: relative;
    z-index: 1;
    //scroll-snap-points-y: repeat(100vh);
    //scroll-snap-type: y mandatory;
    //scroll-behavior: smooth;
  }

  main::-webkit-scrollbar,
  body::-webkit-scrollbar {
    display: none;
  }

  #app {
    width: 100vw;
  }

  .mobile {
    display: none;
  }

}

@import url('https://fonts.googleapis.com/css2?family=Unbounded:wght@400;600;700&display=swap');

:root {
  --red: #ff6332;
  --purple: #b561f6;
  --green: #98da2c;
  --blue: #2cc5da;
  --light-bg: #d5d2d9;
  --input: #41354a;
}

.box-container {
  position: absolute;
  left: 0;
  width: 100vw;
  z-index: 100;
  bottom: 30%;
}

.box {
  width: 100px;
  height: 100px;
  background-color: red;
}

.block {
  display: grid;
  grid-template: 1fr / auto auto;
  min-height: calc(100vh - 48px);
  opacity: 1;
  transition: 300ms opacity ease;
  scroll-snap-align: start;
}

.block__left {
  display: flex;
  flex-direction: column;
  gap: 32px;
  justify-content: center;
  width: calc(50vw - 15px);
  padding: 0 0 0 176px;
  position: relative;
  z-index: 1;
}

.block__title__icon {
  width: 64px;
  height: 40px;
  border-radius: 24px;
  display: grid;
  place-items: center;
  padding: 8px;
}

.block__right {
  width: calc(50vw - 12px);

}

.block__title {
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 56px;
  color: #000;

  &>span {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.splide__arrows {
  display: none;
}

.splide {
  height: fit-content;
}

.block__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 157%;

  &>b {
    font-weight: 600;
  }
}

.move__up {
  animation: 500ms ease forwards __moveUp 0ms;
}

.move__down {
  animation: 500ms ease forwards __moveDown 0ms;
}

.move__left {
  animation: 500ms ease forwards __moveLeft 0ms;
}

[data-animation-type=move-up] {
  transform: translate(0, 100vh);
  opacity: 0;
}

[data-animation-type=move-down] {
  transform: translate(0, -100vh);
  opacity: 0;
}

[data-animation-type=move-left] {
  transform: translate(100vw, 0);
}

.carousel__button {
  background-color: var(--green);
  padding: 15px 0;
  border-radius: 24px;
  display: grid;
  place-items: center;
  transition: 300ms ease all;
  position: relative;
  z-index: 0;
  width: 96px;

  &>svg {
    transition: 300ms ease all;
  }

  &:hover>svg {
    transform: translate(10px, 0);
  }

  &:active>svg {
    transform: translate(10px, 0);
  }

  &_active {
    opacity: 1;
    transform: translate(0);
    pointer-events: all;
  }

  &_disabled {
    opacity: 0;
    transform: translate(-114px, 0);
    pointer-events: none;
  }

  &__back {
    z-index: -1;
    background-color: black;
    transform: rotate(180deg);

    &_active {
      opacity: 1;
      transform: translate(0) rotate(180deg);
      pointer-events: all;
      z-index: 1;
    }

    &_disabled {
      opacity: 0;
      transform: translate(114px) rotate(180deg);
      pointer-events: none;
      z-index: -1;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    width: 472px;
    bottom: -62px;
    gap: 16px;
  }
}

.disappear {
  animation: __disappear 500ms ease forwards;
}

.appear {
  animation: __appear 500ms ease forwards;
}


@keyframes __disappear {
  from {
    opacity: 1;
    width: 100%;
    height: 100%;
  }

  to {
    opacity: 0;
    width: 50%;
    height: 50%;
  }
}

@keyframes __appear {
  from {
    opacity: 0;
    width: 50%;
    height: 50%;
  }

  to {
    opacity: 1;
    width: 100%;
    height: 100%;
  }
}

@keyframes __moveUp {
  from {
    transform: translate(0, 100vh);
    opacity: 0;
  }

  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes __moveDown {
  from {
    transform: translate(0, -100vh);
    opacity: 0;
  }

  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes __moveLeft {
  from {
    transform: translate(100vw, 0);
  }

  to {
    transform: translate(0, 0);
  }
}

@media screen and (min-width: 2090px) {
  .carousel__button__wrapper {
    display: none;
  }
}

@media screen and (max-width: 1600px) {
  .block__left {
    padding: 0 0 0 144px;
    gap: 24px;
  }

  .block__title {
    font-size: 48px;
    line-height: 117%;
  }
}

@media screen and (max-width: 1440px) {
  .block__left {
    padding: 0 0 0 32px;
  }
}

@media screen and (max-width: 1100px) {
  .block__left {
    padding: 0;
  }

  .block__right {
    width: 50vw;
  }

  main {
    padding: 24px 0 24px 24px;
  }
}

@media screen and (max-width: 660px) {
  .dekstop {
    display: none;
  }

  .mobile {
    display: block;
  }
}
</style>