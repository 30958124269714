<template>
  <div class="block how-it-work" data-animation-trigger="true">
    <div class="block__left" data-animation="true" data-animation-type="move-up">
      <div class="block__title">
        <span>
          <span class="block__title__icon">
            <img src="@/assets/img/titleIcon2.svg">
          </span>
          {{ $t("CharlaHowItWork.title_part1") }}
        </span>
        <span><b>Charla</b> {{$t("CharlaHowItWork.title_part2")}}</span>
      </div>
      <div class="how-it-work__slogan">
        {{ $t("CharlaHowItWork.slogan_part1") }} <b>⟶</b> {{ $t("CharlaHowItWork.slogan_part2") }}
      </div>
      <p class="block__text" v-html='$t("CharlaHowItWork.info")'></p>

    </div>
    <div class="block__right" data-animation="true" data-animation-type="move-up">
      <div class="how-it-work__carousel" data-carousel="true" data-hidden-index="0">
        <div class="how-it-work__carousel__wrapper">
          <div class="how-it-work__carousel__item">
            <div class="how-it-work__carousel__item__container">
              <div class="step-title">
                {{$t("CharlaHowItWork.slide1")}}
              </div>
              <img class="how-it-work__carousel__item__screenshot" src="@/assets/img/HowItWorkScreen1.webp"
              v-if="Tr.currentLocale !=='ru'">
              <img class="how-it-work__carousel__item__screenshot" src="@/assets/img/HowItWorkScreen1_ru.webp"
                   v-if="Tr.currentLocale ==='ru'">
              <div class="how-it-work__carousel__item__border-left"></div>
              <div class="how-it-work__carousel__item__border-top"></div>
              <div class="how-it-work__carousel__item__circle"></div>
              <div class="how-it-work__carousel__item__border-right"></div>
            </div>
          </div>
          <div class="how-it-work__carousel__item">
            <div class="how-it-work__carousel__item__container">
              <div class="step-title">
                {{$t("CharlaHowItWork.slide2")}}
              </div>
              <img class="how-it-work__carousel__item__screenshot" src="@/assets/img/HowItWorkScreen3.webp" v-if="Tr.currentLocale !=='ru'">
              <img class="how-it-work__carousel__item__screenshot" src="@/assets/img/HowItWorkScreen3_ru.webp" v-if="Tr.currentLocale ==='ru'">
              <div class="how-it-work__carousel__item__border-left"></div>
              <div class="how-it-work__carousel__item__border-top"></div>
              <div class="how-it-work__carousel__item__circle"></div>
              <div class="how-it-work__carousel__item__border-right"></div>
            </div>
          </div>
          <div class="how-it-work__carousel__item">
            <div class="how-it-work__carousel__item__container">
              <div class="step-title">
                {{$t("CharlaHowItWork.slide3")}}
              </div>
              <img class="how-it-work__carousel__item__screenshot" src="@/assets/img/HowItWorkScreen2.webp" v-if="Tr.currentLocale !=='ru'">
              <img class="how-it-work__carousel__item__screenshot" src="@/assets/img/HowItWorkScreen2_ru.webp" v-if="Tr.currentLocale ==='ru'">
              <div class="how-it-work__carousel__item__border-left"></div>
              <div class="how-it-work__carousel__item__border-top"></div>
              <div class="how-it-work__carousel__item__circle"></div>
              <div class="how-it-work__carousel__item__border-right"></div>
            </div>
          </div>
        </div>
        <div class="carousel__button__wrapper">
          <button :class="this.hiddenIndex > 0 ? 'carousel__button__back_active' : 'carousel__button__back_disabled'" class="carousel__button carousel__button__back" @click="scrollCarousel">
            <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M48 6.58V9.42L41.42 16L40 14.58L45.58 9H8.74234e-08L0 7L45.58 7L40 1.42L41.42 0L48 6.58Z" fill="white" />
            </svg>
          </button>
          <button :class="this.hideArrow ? 'carousel__button_disabled' : 'carousel__button_active'" class="carousel__button" @click="scrollCarousel('right')">
            <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M48 6.58V9.42L41.42 16L40 14.58L45.58 9H8.74234e-08L0 7L45.58 7L40 1.42L41.42 0L48 6.58Z" fill="white" />
            </svg>
          </button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import Tr from "@/i18n/translation";

export default {
  name: "CharlaHowItWork",
  data() {
    return {
      hiddenIndex: 0,
      hideArrow: false
    }
  },
  computed: {
    Tr() {
      return Tr
    },
  },
  methods: {
    scrollCarousel(direction='left'){
      const carouselItems = [...document.querySelectorAll('.how-it-work__carousel__item')]
      const carouselItemsContainer = [...document.querySelectorAll('.how-it-work__carousel__item__container')]
      if (direction === 'right'){
        carouselItemsContainer.forEach((e,i) => {
          if (i === this.hiddenIndex){
            e.classList.add('disappear')
          } else if (i !== this.hiddenIndex && !e.classList.contains('disappear')){
            carouselItems[i].style.transition = '500ms transform ease'
            carouselItems[i].style.transform = `translate(calc(-${100 * (this.hiddenIndex + 1)}% - ${16 * (this.hiddenIndex + 1)}px), 0)`
          }
        })

        this.hiddenIndex += 1
      } else {
        this.hiddenIndex -= 1

        carouselItemsContainer.forEach((e, i) => {
          if (i === this.hiddenIndex){
            e.classList.add('appear')
            setTimeout(() => {
              e.classList.remove('disappear')
              e.classList.remove('appear')
            }, 500)
          } else if (i !== this.hiddenIndex && !e.classList.contains('disappear')) {
            carouselItems[i].style.transition = '500ms transform ease'
            carouselItems[i].style.transform = `translate(calc(-${100 * (this.hiddenIndex)}% - ${16 * (this.hiddenIndex)}px), 0)`
          }
        })
      }

      const carousel = document.querySelector('.how-it-work__carousel');
      carousel.dataset.hiddenIndex = this.hiddenIndex
    }
  },
  mounted() {
    const splideContainer= document.querySelector('.splide')

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          splideContainer.dataset.stopScroll = 'false'
          this.hideArrow = true
        } else {
          splideContainer.dataset.stopScroll = 'true'
          this.hideArrow = false
        }
      })
    }, {
      threshold: 1
    });

    let carouselItem = [...document.querySelectorAll('.how-it-work__carousel__item__container')];
    carouselItem = carouselItem[carouselItem.length - 1]
    observer.observe(carouselItem)

    const carousel = document.querySelector('.how-it-work__carousel')
    this.hiddenIndex = +carousel.dataset.hiddenIndex


    const config = {
      attributes: true,
    };
    const callback = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'attributes'){
          this.hiddenIndex = +carousel.dataset.hiddenIndex
        }
      })
    }

    const mutationObserver = new MutationObserver(callback)
    mutationObserver.observe(carousel, config)
  }
}
</script>

<style scoped lang="scss">
  .how-it-work{
    overflow: hidden;
  }
  .block__title__icon{
    background-color: var(--green);
  }

  .how-it-work__slogan{
    font-weight: 600;
    font-size: 22px;
    line-height: 127%;
    color: #000;

    display: flex;
    align-items: center;
    gap: 5px;

    & > b{
      color: var(--green);
      font-size: 30px;
      font-weight: 700;
    }
  }

  .block__left{
    position: relative;
  }

  .block__right{
    position: relative;
    display: flex;
    align-items: center;
  }

  .how-it-work__carousel{
    position: absolute;
    top: 50%;
    left:-40px;
    transform: translate(0, -50%);
    transition: 400ms left ease;

    &__wrapper{
      display: flex;
      gap: 16px;
      position: relative;
    }

    &__item{
      width: 360px;
      display: grid;
      place-items: center;

      &__container{
        overflow: hidden;
        position: relative;
        width: 100%;
        height: 100%;
        background: #fff;
        border-radius: 32px;
        padding: 8px;
      }

      &__screenshot{
        object-fit: contain;
        width: 100%;
        height: 100%;
      }

      &__icon{
        position: absolute;
        left: 8px;
        z-index: 1;
        top: 8px;
      }

      &__border-left{
        position: absolute;
        background-color: #b561f6;
        left: 0;
        top: 0;
        width: 8px;
        height: 100px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }
      &__border-right{
        position: absolute;
        background-color: #b561f6;
        right: 0;
        top: 0;
        width: 8px;
        height: 100px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      &__border-top{
        position: absolute;
        background-color: #b561f6;
        left: 0;
        top: 0;
        width: 100px;
        height: 8px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }

      &__circle{
        position: absolute;
        background-color: #b561f6;
        width: 30px;
        height: 30px;
        left: 0;
        top: 0;
        z-index: 0;
      }
    }
  }
  .how-it-work__carousel__item__border-top{
    width: 100%;
  }
  .step-title{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    height: 48px;
    text-align: center;
    color: #fff;
    background: #B561F6;
    box-sizing: border-box;
    top: -8px;
    z-index: 10;
  }
  .step-title span{
    position: relative;
    z-index: 10;
  }
  .screen-icon{
    position: absolute;
    top: 14px;
    left: 8px;
  }
  .step-title:before{
    content: '';
    position: absolute;
    width: 100%;
    height: 30px;
    background: #B561F6;
    z-index: -1;
    top: 40px;
  }
  .how-it-work__carousel__item__screenshot{
    position: relative;
    margin-top: -22px;
    z-index: 11;
  }
  .how-it-work__carousel__item__container{
    padding-bottom: 22px;
    background: #fff;
  }
  .block__text{
    max-width: 380px;
    width: 100%;
  }

  @media screen and (max-width: 1600px) {
    .how-it-work__carousel{
      left: -80px;
    }
  }
  @media screen and (max-width: 1300px) {
    .how-it-work__carousel{
      left: -10px;
    }
  }

</style>