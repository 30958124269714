<template>
  <transition name="fade" mode="out-in">
    <div class="burger-menu" v-show="this.getCurrentMenuState === 'opened'">
      <div class="burger-menu__background"></div>
      <div class="burger-menu__wrapper">
        <div class="burger-menu__icons">
          <div class="burger-menu__icons__close">
            <div class="burger-menu__icons__close__container" @click="getNewMenuState('closed'); closeMenu()">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.42 10L20 18.58L18.58 20L10 11.42L1.42 20L0 18.58L8.58 10L0 1.42L1.42 0L10 8.58L18.58 0L20 1.42L11.42 10Z" fill="white" />
              </svg>
            </div>
          </div>
          <div class="burger-menu__icons__socials">
            <a :href="$t('charlaTg')" target="_blank" @click="click__new_id30(); sendMetrik('click__new_id30')" class="footer__socials__item">
              <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.5387 0.159118L0.591584 5.87106C-0.0501582 6.13706 -0.267213 6.66974 0.436474 6.95883L4.52761 8.16645L14.4194 2.48816C14.9596 2.13169 15.5125 2.22674 15.0367 2.61889L6.54093 9.76377L6.27406 12.7875C6.52125 13.2543 6.97385 13.2565 7.26255 13.0245L9.61303 10.9587L13.6386 13.7586C14.5736 14.2727 15.0823 13.9409 15.2835 12.9986L17.9239 1.3857C18.198 0.22578 17.7305 -0.285295 16.5387 0.159118Z" fill="white"/>
              </svg>
            </a>
            <div class="chat-link" @click="setFormState(true);getNewMenuState('closed'); closeMenu()">
              <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 0H8C3.58 0 0 3.58 0 8C0 12.42 3.58 16 8 16H14C18.42 16 22 12.42 22 8C22 3.58 18.42 0 14 0ZM14 14H8C4.69 14 2 11.31 2 8C2 4.69 4.69 2 8 2H14C17.31 2 20 4.69 20 8C20 11.31 17.31 14 14 14ZM7 5H15V7H7V5ZM7 9H11V11H7V9Z" fill="white"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="burger-menu__links">
          <div class="back-group"  v-if="(route.name !== 'home')">
            <a href="/">← {{ $t("burgerMenu.backBtn") }}</a>
          </div>
          <nav class="mobile-burger-nav"  v-if="(windowWidth <= 660) && (route.name === 'home')">
            <div class="burger-menu__links__title">
              {{ $t("burgerMenu.group_title1") }}
            </div>
            <a href="#screen1" class="mobile-burger-nav-link" @click="mobileNavClick()">{{ $t("burgerMenu.link1") }}</a>
            <a href="#screen2" class="mobile-burger-nav-link" @click="mobileNavClick()">{{ $t("burgerMenu.link2") }}</a>
            <a href="#screen3" class="mobile-burger-nav-link" @click="mobileNavClick()">{{ $t("burgerMenu.link3") }}</a>
            <a href="#screen4" class="mobile-burger-nav-link" @click="mobileNavClick()">{{ $t("burgerMenu.link4") }}</a>
            <a href="#screen5" class="mobile-burger-nav-link" @click="mobileNavClick()">{{ $t("burgerMenu.link5") }}</a>
            <a href="#screen6" class="mobile-burger-nav-link" @click="mobileNavClick()">{{ $t("burgerMenu.link6") }}</a>
            <a href="#screen7" class="mobile-burger-nav-link" @click="mobileNavClick()">{{ $t("burgerMenu.link7") }}</a>
            <a href="#screen8" class="mobile-burger-nav-link" @click="mobileNavClick()">{{ $t("burgerMenu.link8") }}</a>
            <a href="#screen9" class="mobile-burger-nav-link" @click="mobileNavClick()">{{ $t("burgerMenu.link9") }}</a>
            <a href="#screen10" class="mobile-burger-nav-link" @click="mobileNavClick()">{{ $t("burgerMenu.link10") }}</a>
          </nav>
          <nav class="burger-menu__links__navigation" v-if="route.name === 'home'">
            <div class="burger-menu__links__title">
              {{ $t("burgerMenu.group_title1") }}
            </div>
            <ul>
              <li class="burger-menu__links__navigation__items" v-for="(item, i) in this.menuItems" :key="i" :class="i === this.getCurrentPos ? 'burger-menu__links__navigation__items_active' : ''" @click="getNewPosition(i); getNewMenuState('closed'); closeMenu()">
                {{item}}
              </li>
            </ul>
          </nav>
          <div class="menu-divider"></div>
          <div class="burger-menu__links__info">
            <div class="burger-menu__links__title">
              {{ $t("burgerMenu.group_title2") }}
            </div>
            <ul>
              <li class="burger-menu__links__info__item" @click="setAboutState(true); getNewMenuState('closed'); closeMenu()">
                {{ $t("burgerMenu.link11") }}
              </li>
              <li class="burger-menu__links__info__item" @click="setLangState(true); getNewMenuState('closed'); closeMenu()">
                {{ $t("burgerMenu.link12") }}
              </li>
<!--              <li class="burger-menu__links__info__item">-->
<!--                <router-link :to="Tr.i18nRoute({ name: 'reviews' })">-->
<!--                  {{ $t("burgerMenu.link13") }}-->
<!--                </router-link>-->
<!--              </li>-->
              <li class="burger-menu__links__info__item" @click="setTermsState(true); getNewMenuState('closed');closeMenu()" >
                {{ $t("burgerMenu.link15") }}
              </li>
              <li class="burger-menu__links__info__item"
                  @click="setWorkingPopup(true); getNewMenuState('closed'); closeMenu()">
                {{ $t("burgerMenu.link17") }}
              </li>
              <li class="burger-menu__links__info__item" @click="setPrivacyState(true); getNewMenuState('closed'); closeMenu()">
                {{ $t("burgerMenu.link16") }}
              </li>
              <li class="burger-menu__links__info__popups">

              </li>
            </ul>
          </div>
          <div class="menu-divider"></div>
          <div class="burger-menu__links__lang">
            <!-- <div class="burger-menu__links__title">
              {{ $t("burgerMenu.group_title3") }}
            </h2> 
            <ul>
              <li class="burger-menu__links__lang__item">
                <a href="#" @click="switchLanguage('ru');getNewMenuState('closed'); closeMenu()">Русский</a>
              </li>
              <li class="burger-menu__links__lang__item">
                <a href="#" @click="switchLanguage('en');getNewMenuState('closed'); closeMenu()">English</a>
              </li>
             <li class="burger-menu__links__lang__item">
               <a href="#" @click="switchLanguage('es');getNewMenuState('closed'); closeMenu()">Español</a>
             </li>
            </ul> -->
          </div>
<!--          <div class="menu-divider"></div>-->
<!--          <div class="external-links">-->
<!--            <div class="external-link" @click="setTermsState(true); getNewMenuState('closed'); closeMenu()">{{ $t("burgerMenu.link15") }}</div>-->
<!--            <div class="external-link" @click="setPrivacyState(true); getNewMenuState('closed'); closeMenu()">{{ $t("burgerMenu.link16") }}</div>-->
<!--            <div class="external-link" @click="setWorkingPopup(true); getNewMenuState('closed'); closeMenu()" v-if="Tr.currentLocale === 'ru'">Условия работы</div>-->
<!--          </div>-->
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapGetters, mapActions} from "vuex";
import {useRoute, useRouter} from 'vue-router';
import { ref, onMounted, onBeforeUnmount } from 'vue';
import Tr from '@/i18n/translation'
import {useGtm} from "@gtm-support/vue-gtm";
import {sendMetrik} from "@/utils/metrica";

export default {
  data(){
    return{
      menuState: 'closed',
      menuItems: [
        this.$t("burgerMenu.link1"),
        this.$t("burgerMenu.link2"),
        this.$t("burgerMenu.link3"),
        this.$t("burgerMenu.link4"),
        this.$t("burgerMenu.link5"),
        this.$t("burgerMenu.link6"),
        this.$t("burgerMenu.link7"),
        this.$t("burgerMenu.link8"),
        this.$t("burgerMenu.link9"),
        this.$t("burgerMenu.link10")],

      previousScrollPosition: 0,
    }
  },
  setup() {
    const route = useRoute();
    const windowWidth = ref(window.innerWidth);

    const handleResize = () => {
      windowWidth.value = window.innerWidth;
    };

    onMounted(() => {
      window.addEventListener('resize', handleResize);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', handleResize);
    });

    const router = useRouter()
    const switchLanguage = async (locale) => {
      await Tr.switchLanguage(locale);
      try {
        await router.replace({params:{locale:locale}})
      }catch (e){
        console.error(e)
        router.push('/')
      }
    }

    return {
      route, windowWidth, switchLanguage, Tr
    };
  },
  mounted(){
    this.getNewMenuState();
    this.menuState = this.getCurrentMenuState;
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('keydown', () => {
      if (event.key === "Escape"){
        this.getNewMenuState('closed');
        this.closeMenu()
      }
    })
  },
  computed: {
    ...mapGetters(['getCurrentPos', 'getCurrentMenuState', 'getLangState'])
  },
  watch: {
    '$i18n.locale'(newLocale, oldLocale) {
      this.menuItems = this.menuItems.map((e,i) => {
        return this.$t(`burgerMenu.link${i + 1}`)}
      )
    },
  },
  methods: {
    ...mapActions(['getNewPosition', 'getNewMenuState', 'setLangState', 'setPrivacyState', 'setTermsState', 'setAboutState', 'setFormState', 'setWorkingPopup']),
    sendMetrik: sendMetrik,
    click__new_id30(){
      const gtm = useGtm()
      gtm.dataLayer().push({
        'event':'click__new_id30',
        'eventCategory':'click',
        'eventAction':'',
        'eventLabel':'new_id30'
      })
    },

    closeMenu() {
      const burgerMenuBackground = document.querySelector('.burger-menu__background');
      const burgerMenuContent = document.querySelector('.burger-menu__wrapper')
      burgerMenuBackground.classList.add('burger-menu_disabled')
      burgerMenuContent.classList.add('burger-menu__wrapper_disabled')

      setTimeout(() => {
        burgerMenuBackground.classList.remove('burger-menu_disabled')
        burgerMenuContent.classList.remove('burger-menu__wrapper_disabled')
      }, 500)
    },
    mobileNavClick(){
      this.getNewMenuState('closed');
      this.closeMenu();
      document.querySelector('.mobile-header').classList.add('hidden')
    },
    handleScroll() {
      //progress bar
      const scroll = document.body.scrollTop || document.documentElement.scrollTop;
      const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrolled = (scroll / height) * 100;
      document.querySelector('#progress').style.width = scrolled + '%';
    },
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
}
</script>

<style scoped lang="scss">
@import "@/scss/mixins";

.burger-menu{
  position: fixed;
  z-index: 99997;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  //padding: 24px;
  transition: 300ms all ease;

  &_disabled{
    animation: 500ms ease forwards __fadeOut;
  }

  &__background{
    background: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1;
    animation: 500ms ease forwards __fadeIn;
  }

  &__icons{
    display: grid;
    grid-template: 1fr / auto 1fr;
    justify-items: flex-end;

    &__close{

      &__container{
        cursor: pointer;
        width: 48px;
        height: 48px;
        background-color: black;
        border-radius: 50%;
        display: grid;
        place-items: center;
      }
    }

    &__socials{
      display: flex;
      gap: 12px;
      padding: 4px 0 0 0;
      & > *{
        display: grid;
        place-items: center;
        width: 40px;
        height: 40px;
        background-color: black;
        border-radius: 50%;
      }
    }
  }

  &__wrapper{
    background-color: white;
    border-radius: 44px;
    display: grid;
    grid-template: 1fr auto / 1fr;
    height: calc(100vh - 48px);
    width: fit-content;
    padding: 24px;
    position: fixed;
    z-index: 99997;
    margin: 24px;
    animation: 500ms ease forwards __moveRight;

    &_disabled{
      animation: 500ms ease forwards __moveLeft;
    }
  }

  &__links{
    grid-template: 1fr / repeat(3, 1fr);
    width: 100%;
    display: flex;

    & > *{
      width: 304px;
    }

    &__title{
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: #000;
    }

    &__navigation{
      display: flex;
      flex-direction: column;
      gap: 32px;
      padding: 0 0 16px 16px;


      & > ul{
        display: flex;
        flex-direction: column;
        gap: 16px;
      }

      &__items{
        font-weight: 400;
        font-size: 14px;
        line-height: 157%;
        color: #000;
        display: flex;
        align-items: center;
        gap: 16px;
        position: relative;
        user-select: none;
        cursor: pointer;

        &::before{
          content: "";
          width: 4px;
          height: 4px;
          margin: 6px;
          border-radius: 50%;
          background-color: black;
        }

        &::after{
          content: "";
          position: absolute;
          left: 0;
          opacity: 0;
          top: 50%;
          transform: translate(0, -50%);
          width: 16px;
          height: 16px;
          border: 1px solid black;
          border-radius: 50%;
          transition: 300ms ease opacity;
        }

        &_active{
          &::after{
            opacity: 1;
          }
        }
      }
    }

    &__info{
      display: flex;
      flex-direction: column;
      gap: 32px;
      padding: 0 0 16px 16px;
      height: 100%;

      &__popups{
        display: flex;
      }


      &__item{
        display: flex;
        align-items: center;
        gap: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 157%;
        color: #000;
        cursor: pointer;
        transition: 300ms ease color;

        &:before{
          content: '';
          background: url("@/assets/img/purple-arrow.svg") no-repeat center/cover;
          width: 16px;
          height: 16px;
          flex: none;
        }

        &:hover{
          color: var(--purple);
        }


        & > a{
          color: black;

          &:visited{
            color: black;

          }
        }
      }

      & > ul{
        display: flex;
        height: 100%;
        flex-direction: column;
        row-gap: 16px;
        & > li:last-of-type{
          align-self: flex-end;
        }
      }
    }

    &__lang{
      display: flex;
      flex-direction: column;
      gap: 32px;
      padding: 0 0 16px 16px;

      & > ul{
        display: grid;
        row-gap: 16px;
        grid-template: repeat(2, auto) 1fr / 1fr;
        height: 100%;


        & > li:last-of-type{
          align-self: flex-end;
        }
      }

      &__item{
        font-weight: 400;
        font-size: 14px;
        line-height: 157%;
        color: #000;
        transition: 300ms ease color;
        cursor: pointer;

        &:hover{
          color: var(--purple);
        }

        & > a{
          color: black;

          &:visited{
            color: black;
          }
        }
      }
    }
  }
}

@keyframes __fadeIn {
  from{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
}

@keyframes __fadeOut {
  from{
    opacity: 1;
  }
  to{
    opacity: 0;
  }
}

@keyframes __moveRight {
  from{
    transform: translate(-100vw, 0);
  }
  to{
    transform: translate(0, 0);
  }
}

@keyframes __moveLeft {
  from{
    transform: translate(0, 0);
  }
  to{
    transform: translate(-100vw, 0);
  }
}

.fade-leave-active{
  transition: all 500ms ease;
}

.fade-leave{
  opacity: 1;
}

.fade-leave-to{
  opacity: 0;
}
.menu-divider, .external-links{
  display: none;
}
ul{
  padding: 0;
}
ul li{
  list-style-type: none;
}
a{
  text-decoration: none;
}
.burger-menu__links__navigation__items{
  transition: .3s ease;
}
.burger-menu__links__navigation__items:hover{
  color: #B561F6;
}
.burger-menu__links a{
  transition: .3s ease;
}
.burger-menu__links a:hover{
  color: #B561F6;
}
.burger-menu__icons__socials a{
  transition: .3s ease;
}
.burger-menu__icons__socials a:hover{
  background-color: #B561F6;
}
.burger-menu__icons__socials .chat-link{
  transition: .3s ease;
  cursor: pointer;
}
.burger-menu__icons__socials .chat-link:hover{
  background-color: #B561F6;
}
.back-group{
  position: absolute;
  top: 100px;
}
.back-group a{
  color: #000;
}
.burger-menu__icons__close__container{

  transition: .3s ease;
}
.burger-menu__icons__close__container:hover{
  background-color: #B561F6;
}
.mobile-burger-nav{
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 0 16px 16px;
}
.mobile-burger-nav .burger-menu__links__title{
  margin-bottom: 16px;
}
.mobile-burger-nav-link{
  display: flex;
  align-items: center;
  gap: 16px;
  color: #000;
}
.mobile-burger-nav-link:before{
  content: '';
  width: 4px;
  height: 4px;
  background: #000;
  border-radius: 50%;
}
.pp-desk{
  display: flex;
  align-items: center;
  gap: 16px;
}
.pp-desk:before{
  content: '';
  background: url("@/components/mobile/mobile/img/purple-arrow.svg") no-repeat center/cover;
  width: 16px;
  height: 16px;
}

@media screen and (max-width: 660px) {
  ul{
    margin: 0;
  }

  .burger-menu{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .burger-menu__wrapper{
    display: flex;
    flex-direction: column;
    padding: 8px;
    border-radius: 26px;
    width: calc(100% - 16px);
    margin: 0;
    height: calc(100% - 16px);
    box-sizing: border-box;
    overflow-y: scroll;
    padding-bottom: 30px;
  }
  .burger-menu__icons{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .burger-menu__icons__close{
    order: 1;
  }
  .burger-menu__icons__close__container{
    width: 36px;
    height: 36px;
  }
  .burger-menu__icons__close__container svg{
    width: 15px;
    height: 15px;
  }
  .burger-menu__icons__socials > *{
    width: 36px;
    height: 36px;
  }
  .burger-menu__links{
    flex-direction: column;
    padding: 0 32px;
    margin-top: 32px
  }
  .burger-menu__links__title{
    font-size: 11px;
    margin: 0;
  }
  .burger-menu__links__navigation__items{
    font-size: 13px;
  }
  .burger-menu__links__info__item > a{
    font-size: 13px;
  }
  .burger-menu__links__lang__item > a{
    font-size: 13px;
  }
  .burger-menu__links__info, .burger-menu__links__lang, .burger-menu__links__navigation{
    padding: 0;
    gap: 24px;
  }
  .menu-divider{
    display: block;
    height: 2px;
    max-width: 298px;
    width: 100%;
    margin: 16px 0 32px 0;
    background: #000;
    flex: none;
  }
  .external-links{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .external-link{
    display: flex;
    align-items: center;
    gap: 16px;
    color: #000;
    font-size: 13px;
  }
  .external-link:before{
    content: '';
    background: url("@/components/mobile/mobile/img/purple-arrow.svg") no-repeat center/cover;
    width: 16px;
    height: 16px;
  }

  .pp-desk, .terms-desk{
    display: none;
  }
  .burger-menu__links__navigation{
    display: none;
  }
  .mobile-burger-nav{
    display: flex;
  }
  .mobile-burger-nav{
    gap: 16px;
  }
  .mobile-burger-nav nav{

  }
  .mobile-burger-nav-link{
    display: flex;
    align-items: center;
    font-size: 13px;
    gap: 16px;
    color: #000;
  }
  .back-group{
    position: static;
    font-size: 13px;
  }
}
</style>