<template>
  <div class="about__popup" v-show="getAboutState">
    <div class="about__background" @click="closeAbout()"></div>
    <div class="about__wrapper">
      <div class="about__container">
        <div class="about__close" @click="closeAbout()">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M11.42 10L20 18.58L18.58 20L10 11.42L1.42 20L0 18.58L8.58 10L0 1.42L1.42 0L10 8.58L18.58 0L20 1.42L11.42 10Z"
              fill="black" />
          </svg>
        </div>
        <div class="about__img">
          <img src="@/assets/img/aboutImg.jpg" alt="">
        </div>
        <div class="about__title">{{ $t("about.title_name") }} <br>{{ $t("about.title1") }}</div>
        <div class="about__block">
          <div class="about__block__title about__block__title_big">{{ $t("about.title2") }}</div>
          <p class="about__block__text" v-html='$t("about.info1")'></p>
          <p class="about__block__text">{{ $t("about.info2") }}</p>
        </div>
        <div class="about__block mobileGrid">
          <div class="about__block__title">{{ $t("about.title3") }}</div>
          <p class="about__block__text">{{ $t("about.info3") }}</p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CharlaAboutPopup",
  methods: {
    ...mapActions(['setAboutState']),
    closeAbout() {
      const aboutWrapper = document.querySelector('.about__wrapper')
      const aboutBackground = document.querySelector('.about__background')

      aboutWrapper.classList.add('about__wrapper__disable')
      aboutBackground.classList.add('about__background__disable')

      setTimeout(() => {
        aboutWrapper.classList.remove('about__wrapper__disable')
        aboutBackground.classList.remove('about__background__disable')
        this.setAboutState(false)
      }, 500)
    }
  },
  computed: {
    ...mapGetters(['getAboutState'])
  }
}
</script>

<style scoped lang="scss">
.about {
  &__background {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    cursor: pointer;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9999;
    animation: 500ms ease forwards __fadeIn;

    &__disable {
      animation: 500ms ease forwards __fadeOut;
    }
  }

  &__close {
    position: absolute;
    background-color: white;
    top: 24px;
    right: -48px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    z-index: 100000;
    transform: translate(50%, 0);
    display: grid;
    place-items: center;
    cursor: pointer;
  }

  &__wrapper {
    position: fixed;
    max-width: 836px;
    background-color: white;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000;
    border-radius: 44px;
    padding: 0 0 64px 0;
    animation: 500ms ease forwards __slideUp;

    &__disable {
      animation: 500ms ease forwards __slideDown;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 48px;
    position: relative;
  }

  &__img {
    padding: 24px 24px 0;
  }

  &__title {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    color: #000;
    padding: 0 64px;
  }

  &__block {
    display: grid;
    grid-template: 1fr 1fr / 1fr 1fr;
    padding: 0 64px;
    column-gap: 48px;
    row-gap: 16px;

    &__title {
      font-weight: 600;
      font-size: 22px;
      line-height: 127%;
      color: #000;

      &_big {
        grid-area: 1 / 1 / 1 / span 2;
      }
    }

    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 157%;
      color: #000;
    }
  }
}

.about__img img {
  width: 100%;
}

@keyframes __fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes __fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes __slideUp {
  from {
    transform: translate(-50%, 120vh);
  }

  to {
    transform: translate(-50%, -50%);
  }
}

@keyframes __slideDown {
  from {
    transform: translate(-50%, -50%);
  }

  to {
    transform: translate(-50%, 120vh);
  }
}

@media screen and (max-width: 660px) {
  .about__wrapper {
    width: calc(100% - 16px);
    height: calc(100% - 16px);
    padding: 0;
    border-radius: 26px;
    box-sizing: border-box;
  }

  .about__img {
    padding: 10px 10px 0;
  }

  .about__img img {
    width: 100%;
    border-radius: 0 100px 100px 0;
  }

  .about__container {
    gap: 24px;
  }

  .about__title {
    font-size: 28px;
    margin: auto auto 20px auto;
    padding: 0;
  }

  .about__title br {
    display: none;
  }

  .about__block__title {
    font-size: 18px;
    margin: 0 0 10px 0;
  }

  .about__block {
    display: flex;
    flex-direction: column;
    padding: 0 24px;
    column-gap: 0;
    row-gap: 0;
  }

  .about__block__text {
    font-size: 13px;
    margin: 10px 0;
  }

  .mobileGrid {
    flex-direction: column-reverse;
  }

  .mobileGrid .about__block__title:nth-child(1) {
    order: 1;
  }

  .mobileGrid .about__block__title:nth-child(2) {
    order: 3;
  }

  .mobileGrid .about__block__text:nth-child(3) {
    order: 2;
  }

  .mobileGrid .about__block__text:nth-child(4) {
    order: 4;
  }

  .about__close {
    background: #000;
    top: 8px;
    right: 28px;
    left: unset;
    width: 36px;
    height: 36px;
  }

  .about__close svg {
    width: 15px;
    height: 15px;
  }

  .about__close svg path {
    fill: #fff;
  }
}
</style>