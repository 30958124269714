import i18n from "@/i18n/index.js";

const Translation = {
    // get defaultLocale() {
    //     return 'ru'
    // },

    // get supportedLocales() {
    //     return ['ru']
    // },

    get currentLocale() {
        return i18n.global.locale.value
    },

    // set currentLocale(newLocale) {
    //     i18n.global.locale.value = newLocale
    // },
    //
    // isLocaleSupported(locale) {
    //     return Translation.supportedLocales.includes(locale)
    // },

    // getUserLocale() {
    //     const locale = window.navigator.language || window.navigator.userLanguage || Translation.defaultLocale;
    //     return {
    //         locale: locale,
    //         localeNoRegion: locale.split('-')[0]
    //     }
    // },
    //
    // getPersistedLocale() {
    //     const persistedLocale = localStorage.getItem('user-locale')
    //     if (Translation.isLocaleSupported(persistedLocale)) {
    //         return persistedLocale
    //     } else {
    //         return null
    //     }
    // },

    // guessDefaultLocale() {
    //     const userPersistedLocale = Translation.getPersistedLocale();
    //     if (userPersistedLocale) {
    //         return userPersistedLocale
    //     }
    //     const userPreferredLocale = Translation.getUserLocale();
    //     if (Translation.isLocaleSupported(userPreferredLocale.locale)) {
    //         return userPreferredLocale.locale
    //     }
    //     if (Translation.isLocaleSupported(userPreferredLocale.localeNoRegion)) {
    //         return userPreferredLocale.localeNoRegion
    //     }
    //
    //     return Translation.defaultLocale
    // },

    // async switchLanguage(newLocale) {
    //     Translation.currentLocale = newLocale
    //     document.querySelector('html').setAttribute('lang', newLocale)
    //     localStorage.setItem("user-locale", newLocale)
    // },

    // async routeMiddleware(to, _from, next) {
    //
    //     const paramLocale = to.params.locale
    //
    //     if (!Translation.isLocaleSupported(paramLocale)) {
    //         return next(Translation.guessDefaultLocale())
    //     }
    //
    //     await Translation.switchLanguage(paramLocale)
    //
    //     return next()
    // },
    // async routeMiddlewarePP(to, _from, next) {
    //     const locale = Translation.guessDefaultLocale();
    //     if (!to.path.startsWith(`/${locale}`)) {
    //         next(`/${locale}${to.path}`);
    //     } else {
    //         next();
    //     }
    //     await Translation.switchLanguage(locale)
    // },
    //
    // i18nRoute(to) {
    //     return {
    //         ...to,
    //         params: {
    //             locale: Translation.currentLocale,
    //             ...to.params
    //         }
    //     }
    // }
}

export default Translation