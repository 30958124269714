<template>
  <CharlaFormPopup></CharlaFormPopup>
  <div class="subPage-background">
    <div class="subPage-wrapper">
      <subPageHeader></subPageHeader>
      <div class="page-title">
        <h1 :class="{ ruh1: Tr.currentLocale === 'ru' }">{{ $t("privacyPolicy.mainTitle") }}</h1>
      </div>
      <div class="pp-wrapper">
        <div class="privacy__wrapper">
          <div class="privacy__container" id="ru_privacy">
            <div class="privacy__block">
              <div class="privacy__title_small">
                <p class="privacy__title__number">1.</p>
                <h2>{{ $t("privacyPolicy.title1") }}</h2>
              </div>
              <!--              <p class="privacy__text">{{$t("privacyPolicy.block1.text1")}}</p>-->
              <br>
              <ol class="privacy__list">
                <li>
                  <p class="privacy__list__number">1.1.</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block1.text1") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">1.2</p>
                  <p class="privacy__text" v-html="$t('privacyPolicy.block1.text2')"></p>
                </li>
              </ol>
            </div>
            <div class="privacy__block">
              <div class="privacy__title_small">
                <p class="privacy__title__number">2.</p>
                <h2>{{ $t("privacyPolicy.title2") }}</h2>
              </div>
              <ol class="privacy__list">
                <li>
                  <p class="privacy__list__number">2.1</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block2.text1") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">2.2</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block2.text2") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">2.3</p>
                  <p class="privacy__text" v-html='$t("privacyPolicy.block2.text3")'></p>
                </li>
                <li>
                  <p class="privacy__list__number">2.4</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block2.text4") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">2.5</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block2.text5") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">2.6</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block2.text6") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">2.7</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block2.text7") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">2.8</p>
                  <p class="privacy__text" v-html='$t("privacyPolicy.block2.text8")'></p>
                </li>
                <li>
                  <p class="privacy__list__number">2.9</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block2.text9") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">2.10</p>
                  <p class="privacy__text" v-html='$t("privacyPolicy.block2.text10")'></p>
                </li>
                <li>
                  <p class="privacy__list__number">2.11</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block2.text11") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">2.12</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block2.text12") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">2.13</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block2.text13") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">2.14</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block2.text14") }}</p>
                </li>
              </ol>
            </div>
            <div class="privacy__block">
              <div class="privacy__title_small">
                <p class="privacy__title__number">3.</p>
                <h2>{{ $t("privacyPolicy.title3") }}</h2>
              </div>
              <ol class="privacy__list">
                <li>
                  <p class="privacy__list__number">3.1</p>
                  <p class="privacy__list__title">{{ $t("privacyPolicy.title3_1") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number"></p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block3_1.text1") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number"></p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block3_1.text2") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number"></p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block3_1.text3") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">3.2</p>
                  <p class="privacy__list__title">{{ $t("privacyPolicy.title3_2") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number"></p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block3_2.text1") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number"></p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block3_2.text2") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number"></p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block3_2.text3") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number"></p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block3_2.text4") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number"></p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block3_2.text5") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number"></p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block3_2.text6") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number"></p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block3_2.text7") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number"></p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block3_2.text8") }}</p>
                </li>
              </ol>
            </div>
            <div class="privacy__block">
              <div class="privacy__title_small">
                <p class="privacy__title__number">4.</p>
                <h2>{{ $t("privacyPolicy.title4") }}</h2>
              </div>
              <ol class="privacy__list">
                <li>
                  <p class="privacy__list__number">4.1</p>
                  <p class="privacy__list__title">{{ $t("privacyPolicy.title4_1") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number"></p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block4_1.text1") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number"></p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block4_1.text2") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number"></p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block4_1.text3") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number"></p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block4_1.text4") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number"></p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block4_1.text5") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number"></p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block4_1.text6") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number"></p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block4_1.text7") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">4.2</p>
                  <p class="privacy__list__title">{{ $t("privacyPolicy.title4_2") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number"></p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block4_2.text1") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number"></p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block4_2.text2") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">4.3</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block4_2.text3") }}</p>
                </li>
              </ol>
            </div>
            <div class="privacy__block">
              <div class="privacy__title_small">
                <p class="privacy__title__number">5.</p>
                <h2>{{ $t("privacyPolicy.title5") }}</h2>
              </div>
              <ol class="privacy__list">
                <li>
                  <p class="privacy__list__number">5.1</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block5.text1") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">5.2</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block5.text2") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">5.3</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block5.text3") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">5.4</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block5.text4") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">5.5</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block5.text5") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">5.6</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block5.text6") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">5.7</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block5.text7") }}</p>
                </li>
              </ol>
            </div>
            <div class="privacy__block">
              <div class="privacy__title_small">
                <p class="privacy__title__number">6.</p>
                <h2>{{ $t("privacyPolicy.title6") }}</h2>
              </div>
              <div class="main__grid">
                <p class="main__text">{{ $t("privacyPolicy.block6.column1.row1") }}</p>
                <p class="main__text">{{ $t("privacyPolicy.block6.column2.row1") }}</p>
                <p class="main__text">{{ $t("privacyPolicy.block6.column1.row2") }}</p>
                <p class="main__text" v-html='$t("privacyPolicy.block6.column2.row2")'></p>
                <p class="main__text">{{ $t("privacyPolicy.block6.column1.row3") }}</p>
                <p class="main__text">{{ $t("privacyPolicy.block6.column2.row3") }}</p>
                <p class="main__text">{{ $t("privacyPolicy.block6.column1.row4") }}</p>
                <p class="main__text" v-html='$t("privacyPolicy.block6.column2.row4")'></p>
              </div>
            </div>
            <div class="privacy__block">
              <div class="privacy__title_small">
                <p class="privacy__title__number">7.</p>
                <h2>{{ $t("privacyPolicy.title7") }}</h2>
              </div>
              <ol class="privacy__list">
                <li>
                  <p class="privacy__list__number">7.1</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block7.text1") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">7.2</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block7.text2") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">7.3</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block7.text3") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">7.4</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block7.text4") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">7.5</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block7.text5") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">7.6</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block7.text6") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">7.7</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block7.text7") }}</p>
                </li>
              </ol>
            </div>
            <div class="privacy__block">
              <div class="privacy__title_small">
                <p class="privacy__title__number">8.</p>
                <h2>{{ $t("privacyPolicy.title8") }}</h2>
              </div>

              <ol class="privacy__list">
                <li>
                  <p class="privacy__list__number">8.1</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block8.text1") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">8.2</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block8.text2") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">8.3</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block8.text3") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">8.4</p>
                  <p class="privacy__text" v-if="Tr.currentLocale === 'ru'">
                    В случае выявления неточностей в персональных данных, Пользователь может актуализировать их
                    самостоятельно, путем направления Оператору уведомление на адрес электронной почты Оператора <a
                      href="mailto:welcome@charla-ai.com" style="color:#B561F6;">welcome@charla-ai.com</a> с пометкой
                    «Актуализация персональных данных».
                  </p>
                  <p class="privacy__text" v-if="Tr.currentLocale === 'en'">
                    In case of finding inaccuracies in personal data, the User may update them independently by sending
                    a notice to the Operator to the Operator's e-mail address <a href="mailto:welcome@charla-ai.com"
                      style="color:#B561F6;">welcome@charla-ai.com</a> with the comment “Personal Data Update”.
                  </p>
                </li>
                <li>
                  <p class="privacy__list__number">8.5</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block8.text5") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">8.6</p>
                  <p class="privacy__text" v-if="Tr.currentLocale === 'ru'">
                    Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив
                    Оператору уведомление посредством электронной почты на электронный адрес Оператора <a
                      href="mailto:welcome@charla-ai.com" style="color:#B561F6;">welcome@charla-ai.com</a> с пометкой
                    «Отзыв согласия на обработку персональных данных».
                  </p>
                  <p class="privacy__text" v-if="Tr.currentLocale === 'en'">
                    The User may withdraw his/her consent to the processing of personal data at any time by sending a
                    notice to the Operator by e-mail to the Operator's e-mail address <a
                      href="mailto:welcome@charla-ai.com" style="color:#B561F6;">welcome@charla-ai.com</a> marked
                    “Withdrawal of consent to the processing of personal data”
                  </p>
                </li>
                <li>
                  <p class="privacy__list__number">8.7</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block8.text7") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">8.8</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block8.text8") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">8.9</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block8.text9") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">8.10</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block8.text10") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">8.11</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block8.text11") }}</p>
                </li>
              </ol>
              <br>
            </div>
            <div class="privacy__block">
              <div class="privacy__title_small">
                <p class="privacy__title__number">9.</p>
                <h2>{{ $t("privacyPolicy.title9") }}</h2>
              </div>
              <ol class="privacy__list">
                <li>
                  <p class="privacy__list__number">9.1</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block9.text1") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">9.2</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block9.text2") }}</p>
                </li>
              </ol>
            </div>
            <div class="privacy__block">
              <div class="privacy__title_small">
                <p class="privacy__title__number">10.</p>
                <h2>{{ $t("privacyPolicy.title10") }}</h2>
              </div>
              <ol class="privacy__list">
                <li>
                  <p class="privacy__list__number">10.1</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block10.text1") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">10.2</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block10.text2") }}</p>
                </li>
              </ol>
            </div>
            <div class="privacy__block">
              <div class="privacy__title_small">
                <p class="privacy__title__number">11.</p>
                <h2>{{ $t("privacyPolicy.title11") }}</h2>
              </div>
              <ol class="privacy__list">
                <li>
                  <p class="privacy__list__number">11.1</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block11.text1") }}</p>
                </li>
              </ol>
            </div>
            <div class="privacy__block">
              <div class="privacy__title_small">
                <p class="privacy__title__number">12.</p>
                <h2>{{ $t("privacyPolicy.title12") }}</h2>
              </div>
              <ol class="privacy__list">
                <li>
                  <p class="privacy__list__number">12.1</p>
                  <p class="privacy__text" v-if="Tr.currentLocale === 'ru'">
                    Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его
                    персональных данных, обратившись к Оператору с помощью электронной почты <a
                      href="mailto:welcome@charla-ai.com" style="color:#B561F6;">welcome@charla-ai.com</a>
                  </p>
                  <p class="privacy__text" v-if="Tr.currentLocale === 'en'">
                    The User may obtain any kind of clarification on issues of interest concerning the processing of
                    his/her personal data by contacting the Operator via e-mail at <a
                      href="mailto:welcome@charla-ai.com" style="color:#B561F6;">welcome@charla-ai.com</a>.
                  </p>
                </li>
                <li>
                  <p class="privacy__list__number">12.2</p>
                  <p class="privacy__text">{{ $t("privacyPolicy.block12.text2") }}</p>
                </li>
                <li>
                  <p class="privacy__list__number">12.3</p>
                  <p class="privacy__text" v-html='$t("privacyPolicy.block12.text3")'></p>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <subPageFooter></subPageFooter>
    </div>
  </div>
</template>

<script>
import Tr from "@/i18n/translation";
import subPageFooter from "@/components/subPages/subPageFooter.vue";
import subPageHeader from "@/components/subPages/subPageHeader.vue";
import CharlaFormPopup from "@/components/CharlaFormPopup.vue";

export default {
  name: "privacyPolicy",
  components: { CharlaFormPopup, subPageHeader, subPageFooter },
  computed: {
    Tr() {
      return Tr
    }
  },

}
</script>

<style scoped lang="sass">
.subPage-background
  background: #F6F5F8
  height: 100%
.subPage-wrapper
  max-width: 1112px
  margin: auto
  padding: 64px 0

.faq-wrapper
  margin: 64px auto

.page-title
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 64px

h1
  font-size: 56px
  font-weight: 700
  margin: 0

  span
    color: #98DA2C

.title-btn
  display: flex
  align-items: center
  justify-content: center
  gap: 16px
  max-width: 240px
  width: 100%
  height: 48px
  border-radius: 24px
  background: #000
  color: #fff
  transition: .3s ease
  cursor: pointer
  border: 1px solid #000

  &:hover
    background: #fff
    color: #000

    svg path
      transition: .3s ease
      fill: #000

p
  font-size: 16px
  line-height: 164%

  &:last-child
    margin-bottom: 0

ul
  padding-left: 30px

  li
    font-size: 16px
    margin-bottom: 12px
    line-height: 164%
    list-style-type: disc

h2
  font-size: 22px
  margin: 20px 0 10px 0

h3
  font-size: 24px
  font-weight: 400
  margin: 0

@media screen and (max-width: 1200px)
  .subPage-wrapper
    margin: 0 40px

@media screen and (max-width: 970px)
  .reviews-grid
    grid-template-columns: 1fr 1fr
  h1
    font-size: 32px

@media screen and (max-width: 660px)
  .page-title
    display: flex
    flex-direction: column
    gap: 22px
  .subPage-wrapper
    margin: 0 20px
    padding: 24px 0
  h1
    font-size: 32px
    font-weight: 400
  h2
    font-size: 24px
  h3
    font-size: 18px

  p
    font-size: 13px
    line-height: 154%
  ul li
    font-size: 13px
    line-height: 154%
  .title-btn
    font-size: 14px
    max-width: 200px


</style>

<style scoped lang="scss">
.main__grid {
  display: grid;
  grid-template: repeat(4, 1fr) / repeat(2, 1fr);
  border: 1px solid #98da2c;
  border-radius: 20px;
  overflow: hidden;
}

.main__text {
  border: 1px solid #98da2c;
  padding: 10px;
}

.privacy {


  &__title {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    color: #000;

    &_small {
      font-weight: 600;
      font-size: 22px;
      line-height: 127%;
      color: #000;
      display: flex;
      align-items: center;
      //gap: 20px;
      padding: 0 0 32px;
    }

    &__number {
      width: 48px;
    }
  }

  &__block {
    display: flex;
    flex-direction: column;

    & .privacy__text:last-of-type {
      padding: 0;
    }
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 157%;
    color: #000;
    padding: 0 0 16px;
  }

  &__list {
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_padding {
      padding: 16px 0 0 0;
    }

    &>li {
      display: grid;
      grid-template: 1fr / 48px 1fr;
    }

    &>.privacy__list_line {
      grid-template: 1fr / 96px 1fr;

      &>p {
        position: relative;

        &>svg {
          position: absolute;
          right: 30px;
          top: 10px;
        }
      }
    }

    &__title {
      font-weight: 600;
      font-size: 14px;
      line-height: 157%;
      color: #000;
    }

    &__number {
      font-weight: 600;
      font-size: 14px;
      line-height: 157%;
      color: var(--green);

      &_uncolored {
        padding: 32px 0 0 0;
        font-weight: 600;
        font-size: 14px;
        line-height: 157%;
        color: #000;
      }
    }
  }
}

.colored__text {
  color: #B561F6;
}

@keyframes __fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes __fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes __slideUp {
  from {
    transform: translate(-50%, 120vh);
  }

  to {
    transform: translate(-50%, 0);
  }
}

@keyframes __slideDown {
  from {
    transform: translate(-50%, 0);
  }

  to {
    transform: translate(-50%, 120vh);
  }
}

@keyframes __slideUpClose {
  from {
    transform: translate(50%, 120vh);
  }

  to {
    transform: translate(50%, 0);
  }
}

@keyframes __slideDownClose {
  from {
    transform: translate(50%, 0);
  }

  to {
    transform: translate(50%, 120vh);
  }
}

.short-line {
  grid-template: 1fr/46px 1fr !important;
}

.privacy__title__number {
  font-size: 22px;
  margin: 20px 0 10px 0;
}

@media screen and (max-width: 660px) {
  .privacy__title_small {
    gap: 20px;
  }

  .privacy__wrapper {
    box-sizing: border-box;
  }

  .privacy__container {
    gap: 24px;
    padding: 16px;
  }

  .privacy__title {
    font-size: 22px;
    margin: 10px 0 0 0
  }

  .privacy__close {
    background: #000;
    top: 18px;
    right: 36px;
    left: unset;
    width: 36px;
    height: 36px;
  }

  .privacy__close svg {
    width: 15px;
    height: 15px;
  }

  .privacy__close svg path {
    fill: #fff;
  }

  .privacy__list {
    gap: 8px;
    padding: 0;
    margin: 0;
  }

  .privacy__text {
    margin: 0;
    font-size: 13px;
  }

  .privacy__block__title {
    font-size: 18px;
    margin: 0 0 32px 0;
    text-align: center;
    padding: 0;
  }

  .privacy__title_small {
    align-items: center;
    padding: 0;
    margin: 20px 0;
  }

  .privacy__title_small h2 {
    font-size: 18px;
    margin: 0;
    padding: 0;
  }

  .privacy__list__title {
    margin: 0;
    padding: 0;
  }

  .privacy__title__number {
    font-size: 18px;
    margin: 0;
    padding: 0;
  }

  .privacy__list__number {
    padding: 0;
    margin: 0;
  }

  .privacy__list__number_uncolored {
    margin: 0;
    padding: 0;
  }

  .main__text {
    font-size: 13px;
    padding: 5px;
    margin: 0;
  }

  .ruh1 {
    font-size: 22px;
    font-weight: bold;
  }
}
</style>