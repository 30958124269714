<template>
  <div class="languages" v-show="getLangState">
    <div class="languages__background" @click="closeLangPopup()"></div>
    <div class="languages__close" @click="closeLangPopup()">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.42 10L20 18.58L18.58 20L10 11.42L1.42 20L0 18.58L8.58 10L0 1.42L1.42 0L10 8.58L18.58 0L20 1.42L11.42 10Z"
            fill="black"/>
      </svg>
    </div>
    <div class="languages__wrapper">
      <div class="languages__container">
        <div class="languages__title" v-html='$t("CharlaLangPopup.title")'></div>
        <p class="languages__text">
          {{ $t("CharlaLangPopup.info") }}
        </p>
        <div class="languages__search">
          <p class="languages__search__title">
            {{ $t("CharlaLangPopup.search_title") }}
          </p>
          <transition name="fade">
            <p class="languages__search__amount" v-if="searchString.length > 0">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16 9.42L16 6.58L9.42 -2.87621e-07L8 1.42L13.58 7L-3.0598e-07 7L-3.93402e-07 9L13.58 9L8 14.58L9.42 16L16 9.42Z"
                    fill="#B561F7"/>
              </svg>
              {{ amountOfFounded }} {{ $t("CharlaLangPopup.langFound") }}
            </p>
          </transition>
          <div class="languages__search__wrapper">
            <input class="languages__search__input" placeholder="..." @keyup="searchLang()">
            <svg class="languages__search__icon" width="16" height="16" viewBox="0 0 16 16" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path
                  d="M16 14.5809L10.88 9.46409C11.58 8.4847 11.99 7.28545 11.99 5.99625C11.99 2.68832 9.3 0 5.99 0C2.68 0 0 2.68832 0 5.99625C0 9.30418 2.69 11.9925 6 11.9925C7.29 11.9925 8.49 11.5728 9.47 10.8832L14.59 16L16 14.5909V14.5809ZM6 9.99375C3.8 9.99375 2 8.20487 2 5.99625C2 3.78763 3.79 1.99875 6 1.99875C8.21 1.99875 10 3.78763 10 5.99625C10 8.20487 8.21 9.99375 6 9.99375Z"
                  fill="black"/>
            </svg>
          </div>
        </div>
        <div class="languages__block" v-if="searchString.length > 0">
          <div class="languages__block__title">{{ $t("CharlaLangPopup.sub_title1") }} “{{ this.searchString }}”</div>
          <div class="languages__block__wrapper">
            <div class="languages__block__row" v-for="(row, index) in currentSearchLength" :key="index">
              <div class="languages__block__row__item" v-for="(item, itemIndex) in currentSearch[index]"
                   :key="itemIndex">
                <img :src="item" alt="Country Flag" class="languages__block__row__item__img">
                <p class="languages__block__row__item__text">{{ itemIndex }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="languages__block" v-if="searchString.length === 0">
          <div class="languages__block__title">{{ $t("CharlaLangPopup.sub_title2") }}</div>
          <div class="languages__block__wrapper">
            <div class="languages__block__row" v-for="(row, index) in popularLength" :key="index">
              <div class="languages__block__row__item" v-for="(item, itemIndex) in popularLangs[index]"
                   :key="itemIndex">
                <img :src="item" alt="Country Flag" class="languages__block__row__item__img">
                <p class="languages__block__row__item__text">{{ itemIndex }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="languages__block" v-if="searchString.length === 0">
          <div class="languages__block__title">{{ $t("CharlaLangPopup.sub_title3") }}</div>
          <div class="languages__block__wrapper">
            <div class="languages__block__row" v-for="(row, index) in langsLength" :key="index">
              <div class="languages__block__row__item" v-for="(item, itemIndex) in langs[index]" :key="itemIndex">
                <img :src="item" alt="Country Flag" class="languages__block__row__item__img">
                <p class="languages__block__row__item__text">{{ itemIndex }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Tr from "@/i18n/translation";

export default {
  name: "CharlaLangPopup",
  setup() {
    return Tr
  },
  data() {
    return {
      langsData: {
        "en": {
          "English": require("@/assets/countryFlags/circle-flags_uk.svg"),
          "Spanish": require("@/assets/countryFlags/emojione_flag-for-spain.svg"),
          "Chinese": require("@/assets/countryFlags/emojione_flag-for-china.svg"),
          "Hindi": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Arabic": require("@/assets/countryFlags/ar.svg"),
          "Bengali": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Portuguese": require("@/assets/countryFlags/circle-flags_pt.svg"),
          "Russian": require("@/assets/countryFlags/circle-flags_ru.svg"),
          "Japanese": require("@/assets/countryFlags/circle-flags_jp.svg"),
          "Punjabi": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Marathi": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Telugu": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
        },
        "es": {
          "Inglés": require("@/assets/countryFlags/circle-flags_uk.svg"),
          "Español": require("@/assets/countryFlags/emojione_flag-for-spain.svg"),
          "Chino": require("@/assets/countryFlags/emojione_flag-for-china.svg"),
          "Hindi": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Árabe": require("@/assets/countryFlags/ar.svg"),
          "Bengalí": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Portugués": require("@/assets/countryFlags/circle-flags_pt.svg"),
          "Ruso": require("@/assets/countryFlags/circle-flags_ru.svg"),
          "Japonés": require("@/assets/countryFlags/circle-flags_jp.svg"),
          "Punjabí": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Marathi": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Telugu": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Turco": require("@/assets/countryFlags/circle-flags_tr.svg"),
          "Coreano": require("@/assets/countryFlags/circle-flags_kr.svg"),
          "Francés": require("@/assets/countryFlags/emojione_flag-for-france.svg"),
          "Alemán": require("@/assets/countryFlags/emojione_flag-for-germany.svg"),
        },
        "ru": {
          "Английский": require("@/assets/countryFlags/circle-flags_uk.svg"),
          "Испанский": require("@/assets/countryFlags/emojione_flag-for-spain.svg"),
          "Китайский": require("@/assets/countryFlags/emojione_flag-for-china.svg"),
          "Хинди": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Арабский": require("@/assets/countryFlags/ar.svg"),
          "Бенгальский": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Португальский": require("@/assets/countryFlags/circle-flags_pt.svg"),
          "Русский": require("@/assets/countryFlags/circle-flags_ru.svg"),
          "Японский": require("@/assets/countryFlags/circle-flags_jp.svg"),
          "Пенджаби": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Маратхи": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Телугу": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Турецкий": require("@/assets/countryFlags/circle-flags_tr.svg"),
          "Корейский": require("@/assets/countryFlags/circle-flags_kr.svg"),
          "Французский": require("@/assets/countryFlags/emojione_flag-for-france.svg"),
          "Немецкий": require("@/assets/countryFlags/emojione_flag-for-germany.svg"),
        },
        "langsEn":{
          "Afrikaans": require("@/assets/countryFlags/emojione_flag-for-south-africa.svg"),
          "Albanian": require("@/assets/countryFlags/alb.svg"),
          "Amharic": require("@/assets/countryFlags/emojione_flag-for-ethiopia.svg"),
          "Arabic": require("@/assets/countryFlags/ar.svg"),
          "Armenian": require("@/assets/countryFlags/emojione_flag-for-armenia.svg"),
          "Assamese": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Azerbaijani": require("@/assets/countryFlags/emojione_flag-for-azerbaijan.svg"),
          "Bashkir": require("@/assets/countryFlags/circle-flags_ru.svg"),
          "Bengali": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Basque": require("@/assets/countryFlags/emojione_flag-for-spain.svg"),
          "Belarusian": require("@/assets/countryFlags/emojione_flag-for-belarus.svg"),
          "Bosnian": require("@/assets/countryFlags/emojione_flag-for-bosnia-and-herzegovina.svg"),
          "Breton": require("@/assets/countryFlags/circle-flags_uk.svg"),
          "Bulgarian": require("@/assets/countryFlags/emojione_flag-for-bulgaria.svg"),
          "Cantonese": require("@/assets/countryFlags/cant.svg"),
          "Catalan": require("@/assets/countryFlags/katolon.svg"),
          "Chinese": require("@/assets/countryFlags/emojione_flag-for-china.svg"),
          "Croatian": require("@/assets/countryFlags/circle-flags_hr.svg"),
          "Czech": require("@/assets/countryFlags/ch.svg"),
          "Danish": require("@/assets/countryFlags/emojione_flag-for-denmark.svg"),
          "Dutch": require("@/assets/countryFlags/emojione_flag-for-netherlands.svg"),
          "English": require("@/assets/countryFlags/circle-flags_uk.svg"),
          "Estonian": require("@/assets/countryFlags/circle-flags_ee.svg"),
          "Faroese": require("@/assets/countryFlags/emojione_flag-for-denmark.svg"),
          "Finnish": require("@/assets/countryFlags/circle-flags_fi.svg"),
          "Galician": require("@/assets/countryFlags/emojione_flag-for-spain.svg"),
          "Georgian": require("@/assets/countryFlags/emojione_flag-for-georgia.svg"),
          "Greek": require("@/assets/countryFlags/emojione_flag-for-greece.svg"),
          "Gujarati": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Haitian Creole": require("@/assets/countryFlags/gk.svg"),
          "Hausa": require("@/assets/countryFlags/emojione_flag-for-sudan.svg"),
          "Hawaiian": require("@/assets/countryFlags/circle-flags_uk.svg"),
          "Hebrew": require("@/assets/countryFlags/emojione_flag-for-israel.svg"),
          "Hindi": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Hungarian": require("@/assets/countryFlags/emojione_flag-for-hungary.svg"),
          "Icelandic": require("@/assets/countryFlags/emojione_flag-for-iceland.svg"),
          "Indonesian": require("@/assets/countryFlags/emojione_flag-for-indonesia.svg"),
          "Italian": require("@/assets/countryFlags/circle-flags_it.svg"),
          "Japanese": require("@/assets/countryFlags/circle-flags_jp.svg"),
          "Javanese": require("@/assets/countryFlags/emojione_flag-for-indonesia.svg"),
          "Kannada": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Kazakh": require("@/assets/countryFlags/emojione_flag-for-kazakhstan.svg"),
          "Khmer": require("@/assets/countryFlags/emojione_flag-for-vietnam.svg"),
          "Lao": require("@/assets/countryFlags/laos.svg"),
          "Latin": require("@/assets/countryFlags/emojione_flag-for-vatican-city.svg"),
          "Latvian": require("@/assets/countryFlags/latis.svg"),
          "Lingala": require("@/assets/countryFlags/lg.svg"),
          "Lithuanian": require("@/assets/countryFlags/litov.svg"),
          "Luxembourgish": require("@/assets/countryFlags/emojione_flag-for-luxembourg.svg"),
          "Macedonian": require("@/assets/countryFlags/circle-flags_mk.svg"),
          "Malagasy": require("@/assets/countryFlags/malagas.svg"),
          "Malay": require("@/assets/countryFlags/circle-flags_mw.svg"),
          "Malayalam": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Maltese": require("@/assets/countryFlags/mailt.svg"),
          "Maori": require("@/assets/countryFlags/maori.svg"),
          "Marathi": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Mongolian": require("@/assets/countryFlags/emojione_flag-for-mongolia.svg"),
          "Myanmar": require("@/assets/countryFlags/mma.svg"),
          "Nepali": require("@/assets/countryFlags/np.svg"),
          "Norwegian": require("@/assets/countryFlags/circle-flags_bv.svg"),
          "Nynorsk": require("@/assets/countryFlags/circle-flags_bv.svg"),
          "Occitan": require("@/assets/countryFlags/circle-flags_fx.svg"),
          "Pashto": require("@/assets/countryFlags/emojione_flag-for-afghanistan.svg"),
          "Persian": require("@/assets/countryFlags/emojione_flag-for-iran.svg"),
          "Polish": require("@/assets/countryFlags/circle-flags_pl.svg"),
          "Portuguese": require("@/assets/countryFlags/circle-flags_pt.svg"),
          "Punjabi": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Romanian": require("@/assets/countryFlags/circle-flags_ro.svg"),
          "Russian": require("@/assets/countryFlags/circle-flags_ru.svg"),
          "Sanskrit": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Serbian": require("@/assets/countryFlags/circle-flags_rs.svg"),
          "Shona": require("@/assets/countryFlags/sho.svg"),
          "Sindhi": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Sinhala": require("@/assets/countryFlags/singal.svg"),
          "Slovak": require("@/assets/countryFlags/circle-flags_si.svg"),
          "Slovenian": require("@/assets/countryFlags/slo.svg"),
          "Somali": require("@/assets/countryFlags/circle-flags_somaliland.svg"),
          "Spanish": require("@/assets/countryFlags/emojione_flag-for-spain.svg"),
          "Sundanese": require("@/assets/countryFlags/emojione_flag-for-indonesia.svg"),
          "Swahili": require("@/assets/countryFlags/suah.svg"),
          "Swedish": require("@/assets/countryFlags/circle-flags_se.svg"),
          "Tagalog": require("@/assets/countryFlags/tag.svg"),
          "Tajik": require("@/assets/countryFlags/taji.svg"),
          "Tamil": require("@/assets/countryFlags/singal.svg"),
          "Tatar": require("@/assets/countryFlags/circle-flags_ru.svg"),
          "Telugu": require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Thai": require("@/assets/countryFlags/circle-flags_th.svg"),
          "Tibetan": require("@/assets/countryFlags/tibet.svg"),
          "Turkmen": require("@/assets/countryFlags/circle-flags_tr.svg"),
          "Ukrainian": require("@/assets/countryFlags/circle-flags_ua.svg"),
          "Urdu": require("@/assets/countryFlags/circle-flags_pk.svg"),
          "Uzbek": require("@/assets/countryFlags/UZ.svg"),
          "Vietnamese": require("@/assets/countryFlags/emojione_flag-for-vietnam.svg"),
          "Welsh": require("@/assets/countryFlags/val.svg"),
          "Yiddish": require("@/assets/countryFlags/emojione_flag-for-germany.svg"),
          "Yoruba": require("@/assets/countryFlags/ng.svg"),
        },
        "langsEs":{
          "Afrikaans":require("@/assets/countryFlags/emojione_flag-for-south-africa.svg"),
          "Albanés":require("@/assets/countryFlags/alb.svg"),
          "Amárico":require("@/assets/countryFlags/emojione_flag-for-ethiopia.svg"),
          "Armenio":require("@/assets/countryFlags/emojione_flag-for-armenia.svg"),
          "Asamés":require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Azerbaiyano":require("@/assets/countryFlags/emojione_flag-for-azerbaijan.svg"),
          "Bashkir":require("@/assets/countryFlags/circle-flags_ru.svg"),
          "Bielorruso":require("@/assets/countryFlags/emojione_flag-for-belarus.svg"),
          "Bosnio":require("@/assets/countryFlags/emojione_flag-for-bosnia-and-herzegovina.svg"),
          "Bretón":require("@/assets/countryFlags/circle-flags_uk.svg"),
          "Búlgaro":require("@/assets/countryFlags/emojione_flag-for-bulgaria.svg"),
          "Canarés":require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Cantonés":require("@/assets/countryFlags/cant.svg"),
          "Catalán":require("@/assets/countryFlags/katolon.svg"),
          "Checo":require("@/assets/countryFlags/ch.svg"),
          "Criollo haitiano":require("@/assets/countryFlags/gk.svg"),
          "Croata":require("@/assets/countryFlags/circle-flags_hr.svg"),
          "Danés":require("@/assets/countryFlags/emojione_flag-for-denmark.svg"),
          "Eslovaco":require("@/assets/countryFlags/circle-flags_si.svg"),
          "Esloveno":require("@/assets/countryFlags/slo.svg"),
          "Estonio":require("@/assets/countryFlags/circle-flags_ee.svg"),
          "Feroés":require("@/assets/countryFlags/emojione_flag-for-denmark.svg"),
          "Finés":require("@/assets/countryFlags/circle-flags_fi.svg"),
          "Galés":require("@/assets/countryFlags/val.svg"),
          "Gallego":require("@/assets/countryFlags/emojione_flag-for-spain.svg"),
          "Georgiano":require("@/assets/countryFlags/emojione_flag-for-georgia.svg"),
          "Griego":require("@/assets/countryFlags/emojione_flag-for-greece.svg"),
          "Gujarati":require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Hausa":require("@/assets/countryFlags/emojione_flag-for-sudan.svg"),
          "Hawaiano":require("@/assets/countryFlags/circle-flags_uk.svg"),
          "Hebreo":require("@/assets/countryFlags/emojione_flag-for-israel.svg"),
          "Húngaro":require("@/assets/countryFlags/emojione_flag-for-hungary.svg"),
          "Indonesio":require("@/assets/countryFlags/emojione_flag-for-indonesia.svg"),
          "islandés":require("@/assets/countryFlags/emojione_flag-for-iceland.svg"),
          "Italiano":require("@/assets/countryFlags/circle-flags_it.svg"),
          "Javanés":require("@/assets/countryFlags/emojione_flag-for-indonesia.svg"),
          "Jemer":require("@/assets/countryFlags/emojione_flag-for-vietnam.svg"),
          "Kazajo":require("@/assets/countryFlags/emojione_flag-for-kazakhstan.svg"),
          "Lao":require("@/assets/countryFlags/laos.svg"),
          "Latín":require("@/assets/countryFlags/emojione_flag-for-vatican-city.svg"),
          "Letón":require("@/assets/countryFlags/latis.svg"),
          "lingala":require("@/assets/countryFlags/lg.svg"),
          "Lituano":require("@/assets/countryFlags/litov.svg"),
          "Luxemburgués":require("@/assets/countryFlags/emojione_flag-for-luxembourg.svg"),
          "Macedonio":require("@/assets/countryFlags/circle-flags_mk.svg"),
          "Malayalam":require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Malayo":require("@/assets/countryFlags/circle-flags_mw.svg"),
          "Malgache":require("@/assets/countryFlags/malagas.svg"),
          "Maltés":require("@/assets/countryFlags/mailt.svg"),
          "Maorí":require("@/assets/countryFlags/maori.svg"),
          "Mongol":require("@/assets/countryFlags/emojione_flag-for-mongolia.svg"),
          "Myanmar":require("@/assets/countryFlags/mma.svg"),
          "Neerlandés":require("@/assets/countryFlags/emojione_flag-for-netherlands.svg"),
          "Nepalí":require("@/assets/countryFlags/np.svg"),
          "Noruego":require("@/assets/countryFlags/circle-flags_bv.svg"),
          "Nynorsk":require("@/assets/countryFlags/circle-flags_bv.svg"),
          "Occitano":require("@/assets/countryFlags/circle-flags_fx.svg"),
          "Pastún":require("@/assets/countryFlags/emojione_flag-for-afghanistan.svg"),
          "Persa":require("@/assets/countryFlags/emojione_flag-for-iran.svg"),
          "Polaco":require("@/assets/countryFlags/circle-flags_pl.svg"),
          "Rumano":require("@/assets/countryFlags/circle-flags_ro.svg"),
          "Sánscrito":require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Serbio":require("@/assets/countryFlags/circle-flags_rs.svg"),
          "Shona":require("@/assets/countryFlags/sho.svg"),
          "Sindhi":require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Sinhala":require("@/assets/countryFlags/singal.svg"),
          "Somalí":require("@/assets/countryFlags/circle-flags_somaliland.svg"),
          "Sueco":require("@/assets/countryFlags/circle-flags_se.svg"),
          "Sundanés":require("@/assets/countryFlags/emojione_flag-for-indonesia.svg"),
          "Swahili":require("@/assets/countryFlags/suah.svg"),
          "Tagalo":require("@/assets/countryFlags/tag.svg"),
          "Tailandés":require("@/assets/countryFlags/circle-flags_th.svg"),
          "Tamil":require("@/assets/countryFlags/singal.svg"),
          "Tártaro":require("@/assets/countryFlags/circle-flags_ru.svg"),
          "Tayiko":require("@/assets/countryFlags/taji.svg"),
          "Tibetano":require("@/assets/countryFlags/tibet.svg"),
          "Turcomano":require("@/assets/countryFlags/circle-flags_tr.svg"),
          "Ucraniano":require("@/assets/countryFlags/circle-flags_ua.svg"),
          "Urdu":require("@/assets/countryFlags/circle-flags_pk.svg"),
          "Uzbeko":require("@/assets/countryFlags/UZ.svg"),
          "Vasco":require("@/assets/countryFlags/emojione_flag-for-spain.svg"),
          "Vietnamita":require("@/assets/countryFlags/emojione_flag-for-vietnam.svg"),
          "Yiddish":require("@/assets/countryFlags/emojione_flag-for-germany.svg"),
          "Yoruba":require("@/assets/countryFlags/ng.svg"),
        },
        "langsRu":{
          "Азербайджанский":require("@/assets/countryFlags/emojione_flag-for-azerbaijan.svg"),
          "Албанский":require("@/assets/countryFlags/alb.svg"),
          "Амхарский":require("@/assets/countryFlags/emojione_flag-for-ethiopia.svg"),
          "Армянский":require("@/assets/countryFlags/emojione_flag-for-armenia.svg"),
          "Ассамский":require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Африкаанс":require("@/assets/countryFlags/emojione_flag-for-south-africa.svg"),
          "Баскский":require("@/assets/countryFlags/emojione_flag-for-spain.svg"),
          "Башкирский":require("@/assets/countryFlags/circle-flags_ru.svg"),
          "Белорусский":require("@/assets/countryFlags/emojione_flag-for-belarus.svg"),
          "Болгарский":require("@/assets/countryFlags/emojione_flag-for-bulgaria.svg"),
          "Боснийский":require("@/assets/countryFlags/emojione_flag-for-bosnia-and-herzegovina.svg"),
          "Бретонский":require("@/assets/countryFlags/circle-flags_uk.svg"),
          "Валлийский":require("@/assets/countryFlags/val.svg"),
          "Венгерский":require("@/assets/countryFlags/emojione_flag-for-hungary.svg"),
          "Вьетнамский":require("@/assets/countryFlags/emojione_flag-for-vietnam.svg"),
          "Гавайский":require("@/assets/countryFlags/circle-flags_uk.svg"),
          "Гаитянский Креольский":require("@/assets/countryFlags/gk.svg"),
          "Галисийский":require("@/assets/countryFlags/emojione_flag-for-spain.svg"),
          "Голландский":require("@/assets/countryFlags/emojione_flag-for-netherlands.svg"),
          "Греческий":require("@/assets/countryFlags/emojione_flag-for-greece.svg"),
          "Грузинский":require("@/assets/countryFlags/emojione_flag-for-georgia.svg"),
          "Гуджарати":require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Датский":require("@/assets/countryFlags/emojione_flag-for-denmark.svg"),
          "Иврит":require("@/assets/countryFlags/emojione_flag-for-israel.svg"),
          "Идиш":require("@/assets/countryFlags/emojione_flag-for-germany.svg"),
          "Индонезийский":require("@/assets/countryFlags/emojione_flag-for-indonesia.svg"),
          "Исландский":require("@/assets/countryFlags/emojione_flag-for-iceland.svg"),
          "Итальянский":require("@/assets/countryFlags/circle-flags_it.svg"),
          "Йоруба":require("@/assets/countryFlags/ng.svg"),
          "Казахский":require("@/assets/countryFlags/emojione_flag-for-kazakhstan.svg"),
          "Каннада":require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Кантонский":require("@/assets/countryFlags/cant.svg"),
          "Каталонский":require("@/assets/countryFlags/katolon.svg"),
          "Кхмерский":require("@/assets/countryFlags/emojione_flag-for-vietnam.svg"),
          "Лаос":require("@/assets/countryFlags/laos.svg"),
          "Латинский":require("@/assets/countryFlags/emojione_flag-for-vatican-city.svg"),
          "Латышский":require("@/assets/countryFlags/latis.svg"),
          "Лингала":require("@/assets/countryFlags/lg.svg"),
          "Литовский":require("@/assets/countryFlags/litov.svg"),
          "Люксембургский":require("@/assets/countryFlags/emojione_flag-for-luxembourg.svg"),
          "Македонский":require("@/assets/countryFlags/circle-flags_mk.svg"),
          "Малагасийский":require("@/assets/countryFlags/malagas.svg"),
          "Малайский":require("@/assets/countryFlags/circle-flags_mw.svg"),
          "Малаялам":require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Мальтийский":require("@/assets/countryFlags/mailt.svg"),
          "Маори":require("@/assets/countryFlags/maori.svg"),
          "Монгольский":require("@/assets/countryFlags/emojione_flag-for-mongolia.svg"),
          "Мьянма":require("@/assets/countryFlags/mma.svg"),
          "Непальский":require("@/assets/countryFlags/np.svg"),
          "Норвежский":require("@/assets/countryFlags/circle-flags_bv.svg"),
          "Нюнорск":require("@/assets/countryFlags/circle-flags_bv.svg"),
          "Окситанский":require("@/assets/countryFlags/circle-flags_fx.svg"),
          "Персидский":require("@/assets/countryFlags/emojione_flag-for-iran.svg"),
          "Польский":require("@/assets/countryFlags/circle-flags_pl.svg"),
          "Пушту":require("@/assets/countryFlags/emojione_flag-for-afghanistan.svg"),
          "Румынский":require("@/assets/countryFlags/circle-flags_ro.svg"),
          "Санскрит":require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Сербский":require("@/assets/countryFlags/circle-flags_rs.svg"),
          "Сингальский":require("@/assets/countryFlags/singal.svg"),
          "Синдхи":require("@/assets/countryFlags/emojione_flag-for-india.svg"),
          "Словацкий":require("@/assets/countryFlags/circle-flags_si.svg"),
          "Словенский":require("@/assets/countryFlags/slo.svg"),
          "Сомали":require("@/assets/countryFlags/circle-flags_somaliland.svg"),
          "Суахили":require("@/assets/countryFlags/suah.svg"),
          "Сунданский":require("@/assets/countryFlags/emojione_flag-for-indonesia.svg"),
          "Тагальский язык":require("@/assets/countryFlags/tag.svg"),
          "Таджикский":require("@/assets/countryFlags/taji.svg"),
          "Тайский":require("@/assets/countryFlags/circle-flags_th.svg"),
          "Тамильский":require("@/assets/countryFlags/singal.svg"),
          "Татарский":require("@/assets/countryFlags/circle-flags_ru.svg"),
          "Тибетский":require("@/assets/countryFlags/tibet.svg"),
          "Туркменский":require("@/assets/countryFlags/circle-flags_tr.svg"),
          "Узбекский":require("@/assets/countryFlags/UZ.svg"),
          "Украинский":require("@/assets/countryFlags/circle-flags_ua.svg"),
          "Урду":require("@/assets/countryFlags/circle-flags_pk.svg"),
          "Фарерский":require("@/assets/countryFlags/emojione_flag-for-denmark.svg"),
          "Финский":require("@/assets/countryFlags/circle-flags_fi.svg"),
          "Хауса":require("@/assets/countryFlags/emojione_flag-for-sudan.svg"),
          "Хорватский":require("@/assets/countryFlags/circle-flags_hr.svg"),
          "Чешский":require("@/assets/countryFlags/ch.svg"),
          "Шведский":require("@/assets/countryFlags/circle-flags_se.svg"),
          "Шона":require("@/assets/countryFlags/sho.svg"),
          "Эстонский":require("@/assets/countryFlags/circle-flags_ee.svg"),
          "Яванский":require("@/assets/countryFlags/emojione_flag-for-indonesia.svg"),
        }
      },
      popularLangs: {},
      popularLength: '',
      langs: {},
      langsLength: '',
      searchString: '',
      searchLangs: '',
      searchLength: '',
      currentSearch: '',
      currentSearchLength: '',
      amountOfFounded: ''
    }
  },
  watch: {
    '$i18n.locale'(newLocale, oldLocale) {
      this.switchLangFunc();
    },
  },
  computed: {
    ...mapGetters(['getLangState']),
  },
  methods: {
    ...mapActions(['setLangState']),
    getPopularLength() {
      return Math.ceil(Object.entries(this.popularLangs).length / 4)
    },
    getLangLength() {
      return Math.ceil(Object.entries(this.langs).length / 4)
    },

    searchLang() {
      const searchInput = document.querySelector('.languages__search__input')
      this.searchString = searchInput.value


      this.currentSearch = Object.fromEntries(
          Object.entries(this.searchLangs).filter((lang) => {
            return lang[0].toLowerCase().startsWith(this.searchString.toLowerCase())
          })
      )

      this.amountOfFounded = Object.entries(this.currentSearch).length

      this.currentSearchLength = Math.ceil(Object.entries(this.currentSearch).length / 4)
      let newArray = []

      for (let i = 0; i < this.currentSearchLength; i++) {
        newArray[i] = Object.entries(this.currentSearch).slice((i * 4), (i * 4) + 4);
      }

      this.currentSearch = newArray.map(e => {
        return Object.fromEntries(e)
      })
    },
    fitContent() {
      return true
    },
    closeLangPopup() {
      const langPopup = document.querySelector('.languages__wrapper');
      const langBackground = document.querySelector('.languages__background');
      const langClose = document.querySelector('.languages__close');

      langPopup.classList.add('lang__close')
      langBackground.classList.add('lang__background__close')
      langClose.classList.add('lang__close__disable')

      setTimeout(() => {
        this.setLangState(false)
        langPopup.classList.remove('lang__close')
        langBackground.classList.remove('lang__background__close')
        langClose.classList.remove('lang__close__disable')
        this.searchString = '';
        document.querySelector('.languages__search__input').value ="";
      }, 500)
    },
    switchLangFunc(){
      switch (Tr.currentLocale) {
        case 'en':
          this.popularLangs = this.langsData.en
          this.langs = this.langsData.langsEn
          break;
        case 'es':
          this.popularLangs = this.langsData.es
          this.langs = this.langsData.langsEs
          break;
        case 'ru':
          this.popularLangs = this.langsData.ru
          this.langs = this.langsData.langsRu
          break;
      }
      this.popularLength = this.getPopularLength()
      let newArray = []
      for (let i = 0; i < this.popularLength; i++) {
        newArray[i] = Object.entries(this.popularLangs).slice((i * 4), (i * 4) + 4);
      }
      this.searchLangs = {...this.langs, ...this.popularLangs}
      this.popularLangs = newArray.map(e => {
        return Object.fromEntries(e)
      })
      this.langsLength = this.getLangLength()
      newArray = []
      for (let i = 0; i < this.langsLength; i++) {
        newArray[i] = Object.entries(this.langs).slice((i * 4), (i * 4) + 4);
      }
      this.langs = newArray.map(e => {
        return Object.fromEntries(e)
      })
    }
  },
  mounted() {
    this.switchLangFunc();
    this.fitContent()
    window.addEventListener('keydown', () => {
      if (event.key === "Escape") {
        this.closeLangPopup()
      }
    })
  }
}
</script>

<style scoped lang="scss">
.languages {

  &__background {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999;
    animation: 500ms ease __fadeIn forwards;
  }

  &__close {
    position: fixed;
    background-color: white;
    top: 48px;
    left: calc(50% + 418px);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    z-index: 100000;
    transform: translate(50%, 0);
    display: grid;
    place-items: center;
    cursor: pointer;
    animation: 500ms ease forwards __slideUpClose;
  }

  &__wrapper {
    position: fixed;
    top: 24px;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: white;
    max-width: 836px;
    width: 100%;
    height: calc(100% - 48px);
    z-index: 10000;
    border-radius: 44px;
    padding: 64px;
    overflow: hidden scroll;
    animation: 500ms ease forwards __slideUp;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 48px;
  }

  &__title {
    font-weight: 600;
    font-size: 40px;
    line-height: 120%;
    color: #000;
  }

  &__text {
    font-weight: 400;
    font-size: 14px;
    line-height: 157%;
    color: #000;
  }

  &__search {
    display: grid;
    grid-template: repeat(2, auto) / 1fr auto;
    gap: 8px;

    &__amount {
      display: flex;
      gap: 8px;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: #000;
      opacity: 1;

    }

    &__title {
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      color: #000;
    }

    &__wrapper {
      width: 100%;
      position: relative;
      grid-area: 2 / 1 / 2 / span 2;

    }

    &__icon {
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translate(0, -50%);

      & > path {
        transition: 300ms ease all;
      }
    }

    &__input {
      border: 1px solid #000;
      border-radius: 20px;
      padding: 9px 16px 9px 40px;
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 157%;
      transition: 300ms ease all;
      color: black;

      &:focus {
        background-color: var(--input);
        color: #fff;
      }

      &:focus + .languages__search__icon > path {
        fill: white;
      }

      &:focus::placeholder {
        color: white;
      }

      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 157%;
        color: #000;
      }
    }
  }

  &__block {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__title {
      font-weight: 600;
      font-size: 22px;
      line-height: 127%;
      color: #000;
    }

    & .languages__block__row:last-of-type {
      border-bottom: 2px solid black;
    }

    &__row {
      padding: 24px 0;
      border-top: 2px solid black;
      display: grid;
      grid-template: 1fr / repeat(4, 1fr);
      gap: 10px;

      &__item {
        display: flex;
        align-items: center;
        gap: 8px;

        &__img {
          width: 32px;
          height: 32px;
          padding: 2px;
          border: 2px solid var(--light-bg);
          border-radius: 16px;
        }

        &__text {
          font-weight: 400;
          font-size: 14px;
          line-height: 157%;
          color: #000;
        }
      }
    }
  }
}

.lang__close {
  animation: 500ms ease forwards __slideDown;
}

.lang__background__close {
  animation: 500ms ease forwards __fadeOut;
}

.lang__close__disable {
  animation: 500ms ease forwards __slideDownClose;
}

.fade-enter-active {
  animation: __fadeIn 300ms ease forwards;
}

.fade-leave-active {
  animation: __fadeOut 300ms ease forwards;
}

@keyframes __fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes __fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes __slideUpClose {
  from {
    transform: translate(50%, 120vh);
  }
  to {
    transform: translate(50%, 0);
  }
}

@keyframes __slideDownClose {
  from {
    transform: translate(50%, 0);
  }
  to {
    transform: translate(50%, 120vh);
  }
}

@keyframes __slideUp {
  from {
    transform: translate(-50%, 120vh);
  }
  to {
    transform: translate(-50%, 0);
  }
}

@keyframes __slideDown {
  from {
    transform: translate(-50%, 0);
  }
  to {
    transform: translate(-50%, 120vh);
  }
}

@media screen and (max-width: 970px) {
  .languages__wrapper {
    max-width: calc(100vw - 16px);
    height: calc(100vh - 16px);
    padding: 8px;
    border-radius: 26px;
    top: 8px;
    box-sizing: border-box;
  }
  .languages__title {
    font-size: 26px;
    margin: 0;
  }
  .languages__container {
    gap: 24px;
  }
  .languages__search__input {
    box-sizing: border-box;
  }
  .languages__block__row {
    grid-template: 1fr/repeat(2, 1fr);
  }
  .languages__close {
    background: #000;
    top: 16px;
    right: 36px;
    left: unset;
    width: 36px;
    height: 36px;
  }
  .languages__close svg path {
    fill: #fff;
  }
  .languages__close svg {
    width: 15px;
    height: 15px;
  }
}
</style>