<template>
  <div class="block faq" data-animation-trigger="true">
    <div class="block__left" data-animation="true" data-animation-type="move-up">
      <div class="block__title">
        <span><span class="block__title__icon">
          <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
               viewBox="0 0 32 8" style="enable-background:new 0 0 32 8;" xml:space="preserve">
            <path class="st0" d="M7.9,3.2C8,3.5,8,3.7,8,4s0,0.5-0.1,0.8l-1-0.2C7,4.4,7,4.2,7,4s0-0.4-0.1-0.6L7.9,3.2z M6.5,2.3l0.8-0.6
              C7,1.3,6.7,1,6.2,0.7L5.7,1.5C6,1.7,6.3,2,6.5,2.3z M3.2,0.1l0.2,1C3.6,1,3.8,1,4,1s0.4,0,0.6,0.1l0.2-1C4.5,0,4.3,0,4,0
              S3.5,0,3.2,0.1z M0.7,1.8l0.8,0.6C1.7,2,2,1.7,2.3,1.5L1.8,0.7C1.3,1,1,1.3,0.7,1.8z M1,4c0-0.2,0-0.4,0.1-0.6l-1-0.2
              C0,3.5,0,3.7,0,4s0,0.5,0.1,0.8l1-0.2C1,4.4,1,4.2,1,4z M1.5,5.7L0.7,6.2C1,6.7,1.3,7,1.8,7.3l0.6-0.8C2,6.3,1.7,6,1.5,5.7z M4,7
              C3.8,7,3.6,7,3.4,6.9l-0.2,1C3.5,8,3.7,8,4,8s0.5,0,0.8-0.1l-0.2-1C4.4,7,4.2,7,4,7z M5.7,6.5l0.6,0.8C6.7,7,7,6.7,7.3,6.2L6.5,5.7
              C6.3,6,6,6.3,5.7,6.5z M18.9,3.4C19,3.6,19,3.8,19,4s0,0.4-0.1,0.6l1,0.2C20,4.5,20,4.3,20,4s0-0.5-0.1-0.8L18.9,3.4z M18.5,2.3
              l0.8-0.6C19,1.3,18.7,1,18.2,0.7l-0.6,0.8C18,1.7,18.3,2,18.5,2.3z M15.2,0.1l0.2,1C15.6,1,15.8,1,16,1s0.4,0,0.6,0.1l0.2-1
              C16.5,0,16.3,0,16,0S15.5,0,15.2,0.1z M12.7,1.8l0.8,0.6C13.7,2,14,1.7,14.3,1.5l-0.6-0.8C13.3,1,13,1.3,12.7,1.8z M13,4
              c0-0.2,0-0.4,0.1-0.6l-1-0.2C12,3.5,12,3.7,12,4s0,0.5,0.1,0.8l1-0.2C13,4.4,13,4.2,13,4z M13.5,5.7l-0.8,0.6
              C13,6.7,13.3,7,13.8,7.3l0.6-0.8C14,6.3,13.7,6,13.5,5.7z M16,7c-0.2,0-0.4,0-0.6-0.1l-0.2,1C15.5,8,15.7,8,16,8s0.5,0,0.8-0.1
              l-0.2-1C16.4,7,16.2,7,16,7z M17.7,6.5l0.6,0.8C18.7,7,19,6.7,19.3,6.2l-0.8-0.6C18.3,6,18,6.3,17.7,6.5z M31.9,3.2l-1,0.2
              C31,3.6,31,3.8,31,4s0,0.4-0.1,0.6l1,0.2C32,4.5,32,4.3,32,4S32,3.5,31.9,3.2z M30.5,2.3l0.8-0.6C31,1.3,30.7,1,30.2,0.7l-0.6,0.8
              C30,1.7,30.3,2,30.5,2.3z M27.2,0.1l0.2,1C27.6,1,27.8,1,28,1s0.4,0,0.6,0.1l0.2-1C28.5,0,28.3,0,28,0S27.5,0,27.2,0.1z M24.7,1.8
              l0.8,0.6C25.7,2,26,1.7,26.3,1.5l-0.6-0.8C25.3,1,25,1.3,24.7,1.8z M25,4c0-0.2,0-0.4,0.1-0.6l-1-0.2C24,3.5,24,3.7,24,4
              s0,0.5,0.1,0.8l1-0.2C25,4.4,25,4.2,25,4z M25.5,5.7l-0.8,0.6C25,6.7,25.3,7,25.8,7.3l0.6-0.8C26,6.3,25.7,6,25.5,5.7z M28,7
              c-0.2,0-0.4,0-0.6-0.1l-0.2,1C27.5,8,27.7,8,28,8s0.5,0,0.8-0.1l-0.2-1C28.4,7,28.2,7,28,7z M29.7,6.5l0.6,0.8
              C30.7,7,31,6.7,31.3,6.2l-0.8-0.6C30.3,6,30,6.3,29.7,6.5z" fill="#FFF"/>
          </svg>
        </span>{{ $t("CharlaQA.title_part1") }}</span>
        <span v-html='$t("CharlaQA.title_part2")'></span>
      </div>
      <div class="faq__slogan">
        {{ $t("CharlaQA.slogan") }}
      </div>
      <div class="faq__buttons" @click="setFormState(true)">
        <button class="faq__buttons__ask">{{ $t("CharlaQA.btn") }}</button>
        <button class="faq__buttons__chat">
          <svg width="22" height="17" viewBox="0 0 22 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 0.5H8C3.58 0.5 0 4.08 0 8.5C0 12.92 3.58 16.5 8 16.5H14C18.42 16.5 22 12.92 22 8.5C22 4.08 18.42 0.5 14 0.5ZM14 14.5H8C4.69 14.5 2 11.81 2 8.5C2 5.19 4.69 2.5 8 2.5H14C17.31 2.5 20 5.19 20 8.5C20 11.81 17.31 14.5 14 14.5ZM7 5.5H15V7.5H7V5.5ZM7 9.5H11V11.5H7V9.5Z" fill="black" />
          </svg>
        </button>
      </div>
      <nav class="faq__navigation">
        <div class="faq__navigation__item" @click="setAboutState(true)">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 9.42L16 6.58L9.42 -2.87621e-07L8 1.42L13.58 7L-3.0598e-07 7L-3.93402e-07 9L13.58 9L8 14.58L9.42 16L16 9.42Z" fill="#B561F7"/>
          </svg>
          {{ $t("CharlaQA.nav1") }}
        </div>
        <div class="faq__navigation__item" @click="setPrivacyState(true)">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 9.42L16 6.58L9.42 -2.87621e-07L8 1.42L13.58 7L-3.0598e-07 7L-3.93402e-07 9L13.58 9L8 14.58L9.42 16L16 9.42Z" fill="#B561F7"/>
          </svg>
          {{ $t("CharlaQA.nav2") }}
        </div>
        <div class="faq__navigation__item" @click="setTermsState(true)">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 9.42L16 6.58L9.42 -2.87621e-07L8 1.42L13.58 7L-3.0598e-07 7L-3.93402e-07 9L13.58 9L8 14.58L9.42 16L16 9.42Z" fill="#B561F7"/>
          </svg>
          {{ $t("CharlaQA.nav3") }}
        </div>
        <div class="faq__navigation__item"  @click="setWorkingPopup(true);">
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16 9.42L16 6.58L9.42 -2.87621e-07L8 1.42L13.58 7L-3.0598e-07 7L-3.93402e-07 9L13.58 9L8 14.58L9.42 16L16 9.42Z" fill="#B561F7"/>
          </svg>
          {{ $t("CharlaQA.nav4") }}
        </div>
      </nav>
    </div>
    <div class="block__right" data-animation="true" data-animation-type="move-up">
      <div class="qa__carousel" data-carousel="true" data-hidden-index="0">
        <div class="qa__carousel__wrapper">
          <div class="qa__carousel__item__wrapper">
            <div class="qa__carousel__item__container">
              <div class="qa__carousel__item" @click="setFaqState(true); setFaqContent('faqPage.faq.qa1')">
                <div class="qa__carousel__item__title">{{ $t("CharlaQA.qa.qa1") }}</div>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 0H4C1.79 0 0 1.79 0 4V16C0 18.21 1.79 20 4 20H16C18.21 20 20 18.21 20 16V4C20 1.79 18.21 0 16 0ZM18 16C18 17.1 17.1 18 16 18H4C2.9 18 2 17.1 2 16V4C2 2.9 2.9 2 4 2H16C17.1 2 18 2.9 18 4V16ZM11 10V16C11 16.55 10.55 17 10 17H4C3.45 17 3 16.55 3 16V10C3 9.45 3.45 9 4 9H10C10.55 9 11 9.45 11 10Z" fill="black" />
                </svg>
              </div>
              <div class="qa__carousel__item" @click="setFaqState(true); setFaqContent('faqPage.faq.qa2')">
                <div class="qa__carousel__item__title">{{ $t("CharlaQA.qa.qa2") }}</div>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 0H4C1.79 0 0 1.79 0 4V16C0 18.21 1.79 20 4 20H16C18.21 20 20 18.21 20 16V4C20 1.79 18.21 0 16 0ZM18 16C18 17.1 17.1 18 16 18H4C2.9 18 2 17.1 2 16V4C2 2.9 2.9 2 4 2H16C17.1 2 18 2.9 18 4V16ZM11 10V16C11 16.55 10.55 17 10 17H4C3.45 17 3 16.55 3 16V10C3 9.45 3.45 9 4 9H10C10.55 9 11 9.45 11 10Z" fill="black" />
                </svg>
              </div>
            </div>
          </div>
          <div class="qa__carousel__item__wrapper">
            <div class="qa__carousel__item__container">
              <div class="qa__carousel__item" @click="setFaqState(true); setFaqContent('faqPage.faq.qa3')">
                <div class="qa__carousel__item__title">{{ $t("CharlaQA.qa.qa3") }}</div>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 0H4C1.79 0 0 1.79 0 4V16C0 18.21 1.79 20 4 20H16C18.21 20 20 18.21 20 16V4C20 1.79 18.21 0 16 0ZM18 16C18 17.1 17.1 18 16 18H4C2.9 18 2 17.1 2 16V4C2 2.9 2.9 2 4 2H16C17.1 2 18 2.9 18 4V16ZM11 10V16C11 16.55 10.55 17 10 17H4C3.45 17 3 16.55 3 16V10C3 9.45 3.45 9 4 9H10C10.55 9 11 9.45 11 10Z" fill="black" />
                </svg>
              </div>
              <div class="qa__carousel__item" @click="setFaqState(true); setFaqContent('faqPage.faq.qa4')">
                <div class="qa__carousel__item__title">{{ $t("CharlaQA.qa.qa4") }}</div>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 0H4C1.79 0 0 1.79 0 4V16C0 18.21 1.79 20 4 20H16C18.21 20 20 18.21 20 16V4C20 1.79 18.21 0 16 0ZM18 16C18 17.1 17.1 18 16 18H4C2.9 18 2 17.1 2 16V4C2 2.9 2.9 2 4 2H16C17.1 2 18 2.9 18 4V16ZM11 10V16C11 16.55 10.55 17 10 17H4C3.45 17 3 16.55 3 16V10C3 9.45 3.45 9 4 9H10C10.55 9 11 9.45 11 10Z" fill="black" />
                </svg>
              </div>
            </div>
          </div>
          <div class="qa__carousel__item__wrapper">
            <div class="qa__carousel__item__container">
              <div class="qa__carousel__item" @click="setFaqState(true); setFaqContent('faqPage.faq.qa5')">
                <div class="qa__carousel__item__title">{{ $t("CharlaQA.qa.qa5") }}</div>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 0H4C1.79 0 0 1.79 0 4V16C0 18.21 1.79 20 4 20H16C18.21 20 20 18.21 20 16V4C20 1.79 18.21 0 16 0ZM18 16C18 17.1 17.1 18 16 18H4C2.9 18 2 17.1 2 16V4C2 2.9 2.9 2 4 2H16C17.1 2 18 2.9 18 4V16ZM11 10V16C11 16.55 10.55 17 10 17H4C3.45 17 3 16.55 3 16V10C3 9.45 3.45 9 4 9H10C10.55 9 11 9.45 11 10Z" fill="black" />
                </svg>
              </div>
              <div class="qa__carousel__item" @click="setFaqState(true); setFaqContent('faqPage.faq.qa6')">
                <div class="qa__carousel__item__title">{{ $t("CharlaQA.qa.qa6") }}</div>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 0H4C1.79 0 0 1.79 0 4V16C0 18.21 1.79 20 4 20H16C18.21 20 20 18.21 20 16V4C20 1.79 18.21 0 16 0ZM18 16C18 17.1 17.1 18 16 18H4C2.9 18 2 17.1 2 16V4C2 2.9 2.9 2 4 2H16C17.1 2 18 2.9 18 4V16ZM11 10V16C11 16.55 10.55 17 10 17H4C3.45 17 3 16.55 3 16V10C3 9.45 3.45 9 4 9H10C10.55 9 11 9.45 11 10Z" fill="black" />
                </svg>
              </div>
            </div>
          </div>
<!--          <div class="qa__carousel__item__wrapper">-->
<!--            <div class="qa__carousel__item__container">-->
<!--              <router-link :to="Tr.i18nRoute({ name: 'faq' })" target="_blank" class="qa__carousel__item">-->
<!--                <h2 class="qa__carousel__item__title">{{ $t("CharlaQA.qa.qa7") }}</h2>-->
<!--                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path d="M16 0H4C1.79 0 0 1.79 0 4V16C0 18.21 1.79 20 4 20H16C18.21 20 20 18.21 20 16V4C20 1.79 18.21 0 16 0ZM18 16C18 17.1 17.1 18 16 18H4C2.9 18 2 17.1 2 16V4C2 2.9 2.9 2 4 2H16C17.1 2 18 2.9 18 4V16ZM11 10V16C11 16.55 10.55 17 10 17H4C3.45 17 3 16.55 3 16V10C3 9.45 3.45 9 4 9H10C10.55 9 11 9.45 11 10Z" fill="black" />-->
<!--                </svg>-->
<!--              </router-link>-->
<!--              <router-link :to="Tr.i18nRoute({ name: 'faq' })" target="_blank" class="qa__carousel__item">-->
<!--                <h2 class="qa__carousel__item__title">{{ $t("CharlaQA.qa.qa8") }}</h2>-->
<!--                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path d="M16 0H4C1.79 0 0 1.79 0 4V16C0 18.21 1.79 20 4 20H16C18.21 20 20 18.21 20 16V4C20 1.79 18.21 0 16 0ZM18 16C18 17.1 17.1 18 16 18H4C2.9 18 2 17.1 2 16V4C2 2.9 2.9 2 4 2H16C17.1 2 18 2.9 18 4V16ZM11 10V16C11 16.55 10.55 17 10 17H4C3.45 17 3 16.55 3 16V10C3 9.45 3.45 9 4 9H10C10.55 9 11 9.45 11 10Z" fill="black" />-->
<!--                </svg>-->
<!--              </router-link>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div class="carousel__button__wrapper">
          <button :class="this.hiddenIndex > 0 ? 'carousel__button__back_active' : 'carousel__button__back_disabled'" class="carousel__button carousel__button__back" @click="scrollCarousel">
            <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M48 6.58V9.42L41.42 16L40 14.58L45.58 9H8.74234e-08L0 7L45.58 7L40 1.42L41.42 0L48 6.58Z" fill="white" />
            </svg>
          </button>
          <button :class="this.hideArrow ? 'carousel__button_disabled' : 'carousel__button_active'" class="carousel__button" @click="scrollCarousel('right')">
            <svg width="48" height="16" viewBox="0 0 48 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M48 6.58V9.42L41.42 16L40 14.58L45.58 9H8.74234e-08L0 7L45.58 7L40 1.42L41.42 0L48 6.58Z" fill="white" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import Tr from '@/i18n/translation'
export default {
  name: "CharlaQA",
  data() {
    return {
      hiddenIndex: 0,
      hideArrow: false
    }
  },
  setup(){
    return{Tr}
  },
  methods: {
    ...mapActions(['getNewFormState', 'setPrivacyState', 'setTermsState', 'setAboutState', 'setFormState', 'setFaqState', 'setFaqContent', 'setWorkingPopup']),
    scrollCarousel(direction='left'){
      const carouselItems = [...document.querySelectorAll('.qa__carousel__item__wrapper')]
      const carouselItemsContainer = [...document.querySelectorAll('.qa__carousel__item__container')]
      const carouselButtons = [...document.querySelectorAll('.carousel__button')]

      carouselButtons.forEach(e => {
        e.style.pointerEvents = 'none'
        setTimeout(() => {
          e.style.pointerEvents = 'all'
        }, 500)
      })

      if (direction === 'right'){
        carouselItemsContainer.forEach((e,i) => {
          if (i === this.hiddenIndex){
            e.classList.add('disappear')
          } else if (i !== this.hiddenIndex && !e.classList.contains('disappear')){
            carouselItems[i].style.transition = '500ms transform ease'
            carouselItems[i].style.transform = `translate(calc(-${100 * (this.hiddenIndex + 1)}% - ${16 * (this.hiddenIndex + 1)}px), 0)`
          }
        })

        this.hiddenIndex += 1
      } else {
        this.hiddenIndex -= 1

        carouselItemsContainer.forEach((e, i) => {
          if (i === this.hiddenIndex){
            e.classList.add('appear')
            setTimeout(() => {
              e.classList.remove('disappear')
              e.classList.remove('appear')
            }, 500)
          } else if (i !== this.hiddenIndex && !e.classList.contains('disappear')) {
            carouselItems[i].style.transition = '500ms transform ease'
            carouselItems[i].style.transform = `translate(calc(-${100 * (this.hiddenIndex)}% - ${16 * (this.hiddenIndex)}px), 0)`
          }
        })

      }

      const carousel = document.querySelector('.qa__carousel');
      carousel.dataset.hiddenIndex = this.hiddenIndex
    }
  },
  mounted() {
    const splideContainer= document.querySelector('.splide')

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          splideContainer.dataset.stopScroll = 'false'
          this.hideArrow = true
        } else {
          splideContainer.dataset.stopScroll = 'true'
          this.hideArrow = false
        }
      })
    }, {
      threshold: 1
    });

    let money__carouselItem = [...document.querySelectorAll('.qa__carousel__item')];
    money__carouselItem = money__carouselItem[money__carouselItem.length - 1]
    observer.observe(money__carouselItem)

    const carousel = document.querySelector('.qa__carousel')
    this.hiddenIndex = +carousel.dataset.hiddenIndex

    const config = {
      attributes: true,
    };
    const callback = (mutationsList) => {
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'attributes'){
          this.hiddenIndex = +carousel.dataset.hiddenIndex
        }
      })
    }

    const mutationObserver = new MutationObserver(callback)
    mutationObserver.observe(carousel, config)
  }
}
</script>

<style scoped lang="scss">
  .block__title__icon{
    background-color: var(--purple);
    padding: 16px;

    & > svg {
      width: 100%;
      height: 100%;
    }
  }

  .block__right{
    position: relative;
  }

  .faq__slogan{
    max-width: 368px;
    font-weight: 400;
    font-size: 14px;
    line-height: 157%;
    color: #000;
  }
  .faq__buttons{
    display: flex;
    width: fit-content;
    gap: 8px;
    transition: .3s ease;

    &__ask{
      font-weight: 600;
      font-size: 14px;
      line-height: 157%;
      color: #fff;
      border-radius: 24px;
      padding: 13px 24px;
      background-color: black;
      transition: .3s ease;
    }

    &__chat{
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 1px solid #000;
      transition: .3s ease;
    }
  }
  .faq__buttons:hover{
    gap: 0;
  }
  .faq__buttons:hover .faq__buttons__ask{
    background: #B561F6;
    border-radius: 24px 0 0 24px;
  }
  .faq__buttons:hover .faq__buttons__chat{
    background: #B561F6;
    border-color: #B561F6;
    border-radius: 0 24px 24px 0;
  }
  .faq__buttons .faq__buttons__chat svg path{
    transition: .3s ease;
  }
  .faq__buttons:hover .faq__buttons__chat svg path{
    fill: #fff;
  }

  .faq__navigation{
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-wrap: wrap;
    font-weight: 400;
    font-size: 14px;
    line-height: 157%;

    &__item{
      color: #000;
      cursor: pointer;
      position: relative;
      transition: 300ms ease all;
      display: flex;
      align-items: center;
      gap: 16px;

      &:hover{
        color: var(--purple);
      }
    }
  }

  .qa__carousel{
    position: absolute;
    left: -44px;
    top: 50%;
    transform: translate(0, -50%);

    &__wrapper{
      display: flex;
      gap: 16px;
      position: relative;
      overflow: hidden;
    }

    &__item{
      border-radius: 32px;
      padding: 32px;
      width: 100%;
      height: 100%;
      background-color: white;
      display: grid;
      grid-template: 1fr / auto 1fr;
      cursor: pointer;
      box-shadow: inset 8px 0 0 0 var(--purple);


      &__wrapper{
        width: 360px;
        height: 360px;
        display: grid;
        place-items: center;
      }

      &__container{
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
        height: 100%;
      }

      & > svg{
        justify-self: flex-end;
      }

      &__title{
        max-width: 234px;
        width: 100%;
        font-weight: 600;
        font-size: 14px;
        line-height: 157%;
        color: #000;
      }
    }
  }

  @media  screen and (max-width: 1850px){
    .faq__navigation {
      flex-direction: column;
      gap: 10px;
    }
  }
  @media screen and (max-width: 1740px) {


    .qa__carousel {
      left: -50px;
    }
  }
</style>